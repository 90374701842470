import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Modal from '../Modal';

import { postRequest } from '../../utils/requester';

import {
  setLoadingRespuesta,
  setErrorEvaluacion,
  setDuration,
  setModal,
} from '../../actions';

import { TextContainer, Text } from './styles';

const Timer = ({
  duration,
  answerData,
  type,
  questionIndex,
  setLoadingRespuesta,
  setErrorEvaluacion,
  setDuration,
  renderedTables,
  showFixed,
}) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [isModalOpen, setModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalType, setModalType] = useState('');
  const [shouldSendCurrentAnswers, setShouldSendCurrentAnswers] = useState(
    false,
  );
  const [shouldSendAnswers, setShouldSendAnswers] = useState(false);
  const [topPosition, setTopPosition] = useState();

  const history = useHistory();

  const matches = useMediaQuery('(max-width:614px)');

  useEffect(() => {
    const header = document.getElementById('header');
    const userInfo = document.getElementById('user-info');
    const userInfoFixed = document.getElementById('user-info-fixed');

    if (showFixed && matches) {
      setTopPosition(header.offsetHeight + userInfoFixed.offsetHeight);
    } else {
      setTopPosition(header.offsetHeight + userInfo.offsetHeight);
    }
  }, [window.scrollY, matches]);

  useEffect(() => {
    const interval = handleInterval();

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    shouldSendCurrentAnswers && sendCurrentAnswers();
  }, [shouldSendCurrentAnswers]);

  useEffect(() => {
    shouldSendAnswers && sendAnswers();
  }, [shouldSendAnswers]);

  const handleInterval = () => {
    let timer = duration * 60,
      hours,
      minutes,
      seconds;
    const interval = setInterval(() => {
      hours = parseInt(timer / 60 / 60, 10);
      minutes = parseInt((timer / 60) % 60, 10);
      seconds = parseInt(timer % 60, 10);

      hours = minutes < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setTimeLeft(`${hours}:${minutes}:${seconds}`);

      if (Math.floor(timer) === 1200) {
        setModalType('eval-timing');
        setModalDescription('Solo te quedan 20min para tu evaluación');
        setModal(true);
      }

      if (Math.floor(timer) === 600) {
        setModalType('eval-timing');
        setModalDescription('Solo te quedan 10min para tu evaluación');
        setModal(true);
      }

      if (Math.floor(timer) === 60) {
        setModalType('eval-timing');
        setModalDescription(
          'Solo te quedan 1min para tu evaluación, favor de finalizar',
        );
        setModal(true);
      }

      if (--timer < 0) {
        clearInterval(interval);
        setModalType('eval-timeout');
        setModalDescription('Enviando resultados...');
        setModal(true);
        if (type === 'Evaluaciones') {
          setShouldSendCurrentAnswers(true);
        } else {
          setShouldSendAnswers(true);
        }
        setTimeout(() => {
          setDuration(null);
          history.push('/fin');
        }, 3000);
      }
    }, 1000);

    return interval;
  };

  const sendAnswers = async () => {
    const respuestas = answerData.respuestas.map((respuesta) => {
      if (answerData.tipo !== 'Opinion_Estudiantil') {
        return {
          id_respuesta: respuesta.id_respuesta,
          id_agrupador: respuesta.id_agrupador,
          id_reactivo: respuesta.id_reactivo,
          id_asignacion: respuesta.id_asignacion,
          id_student_assignment: respuesta.id_student_assignment,
          respuesta: respuesta.respuesta,
          tipo: respuesta.tipo,
        };
      }
      return {
        id_respuesta: respuesta.id_respuesta,
        id_agrupador: respuesta.id_agrupador,
        id_reactivo: respuesta.id_reactivo,
        clave_materia: respuesta.clave_materia,
        clave_docente: respuesta.clave_docente,
        id_asignacion: respuesta.id_asignacion,
        id_student_assignment: respuesta.id_student_assignment,
        respuesta: respuesta.respuesta,
        tipo: respuesta.tipo,
      };
    });

    const newAnswerData = {
      ...answerData,
      respuestas,
    };

    setLoadingRespuesta(true);
    setErrorEvaluacion('');
    try {
      const response = await postRequest(
        '/evaluacion/responder',
        newAnswerData,
      );
      if (response.s === 'OK') {
        setLoadingRespuesta(false);
      } else {
        setLoadingRespuesta(false);
        setErrorEvaluacion(response.m);
      }
    } catch (error) {
      setErrorEvaluacion('Ocurrió un error al enviar respuestas');
      setLoadingRespuesta(false);
      console.error(error);
    }
  };

  const sendCurrentAnswers = async () => {
    if (answerData.respuestas.length === 0) {
      return;
    }

    const respuestas = answerData.respuestas.map((respuesta) => {
      if (answerData.tipo !== 'Opinion_Estudiantil') {
        return {
          id_respuesta: respuesta.id_respuesta,
          id_agrupador: respuesta.id_agrupador,
          id_reactivo: respuesta.id_reactivo,
          id_asignacion: respuesta.id_asignacion,
          id_student_assignment: respuesta.id_student_assignment,
          respuesta: respuesta.respuesta,
          tipo: respuesta.tipo,
        };
      }

      return {
        id_respuesta: respuesta.id_respuesta,
        id_agrupador: respuesta.id_agrupador,
        id_reactivo: respuesta.id_reactivo,
        clave_materia: respuesta.clave_materia,
        clave_docente: respuesta.clave_docente,
        id_asignacion: respuesta.id_asignacion,
        id_student_assignment: respuesta.id_student_assignment,
        respuesta: respuesta.respuesta,
        tipo: respuesta.tipo,
      };
    });

    const answers = respuestas.slice(
      questionIndex,
      questionIndex + renderedTables,
    );

    const newAnswerData = {
      ...answerData,
      respuestas: answers,
    };

    try {
      postRequest('/evaluacion/responder', newAnswerData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TextContainer topPosition={topPosition}>
        <Text>Tiempo disponible: {timeLeft}</Text>
      </TextContainer>

      <Modal
        type={modalType}
        open={isModalOpen}
        title={
          modalType === 'eval-timeout'
            ? 'Tiempo de evaluación caducado'
            : 'Tiempo disponible de evaluación'
        }
        description={modalDescription}
        handleClose={() => setModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  duration: state.duration,
  answerData: state.answerData,
  type: state.type,
  questionIndex: state.questionIndex,
  renderedTables: state.renderedTables,
  isModalOpen: state.isModalOpen,
  showFixed: state.showFixed,
});

const mapDispatchToProps = {
  setLoadingRespuesta,
  setErrorEvaluacion,
  setDuration,
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import store from '../store'
import { setVersion, setTokenKey } from '../actions'

let URL_BASE = 'https://b35fhrn7kl.execute-api.us-east-1.amazonaws.com/test'
let URL_DUMMY = 'https://b35fhrn7kl.execute-api.us-east-1.amazonaws.com/test'
let URL_SESSION_OPENID =
  'https://e8klvcfqt0.execute-api.us-east-1.amazonaws.com/test/sesion'

let version = 'V1.202208241205'
let tokenKey = 'token_eval_a'

export const LINK_ADMINISTRACION_SIEE = 'https://uvmsiee.com/admin/'
export const URL_LOGIN = 'https://uvmsaml.tk/?source=eval&mode=develop'

store.dispatch(setVersion(version))
store.dispatch(setTokenKey(tokenKey))

export async function getRequest(ws, dummy = false, session = false) {
  let token = localStorage.getItem(tokenKey)
  let url
  if (dummy) {
    url = URL_DUMMY
  } else if (session) {
    url = URL_SESSION_OPENID
  } else {
    url = URL_BASE
  }
  let response = await fetch(url + ws, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      tk: token,
    },
  })
  let data = await response.json()
  if (data.s !== 'OK') {
    toast.error(data.m)
  }
  if (data.m === 'token no existe') {
    localStorage.removeItem(tokenKey)
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  } else {
    return data
  }
}

export async function postRequest(ws, json = {}, dummy = false) {
  let token = localStorage.getItem(tokenKey)
  let url = dummy ? URL_DUMMY : URL_BASE
  let response = await fetch(url + ws, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      tk: token,
    },
    body: JSON.stringify(json),
  })
  let data = await response.json()
  if (data.s !== 'OK') {
    toast.error(data.m)
  }
  if (data.m === 'token no existe') {
    localStorage.removeItem(tokenKey)
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  } else {
    return data
  }
}

export async function postLogin(ws, json = {}) {
  let response = await fetch(URL_BASE + ws, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  })
  let data = await response.json()
  if (data.m === 'token no existe') {
    localStorage.removeItem(tokenKey)
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  } else {
    return data
  }
}

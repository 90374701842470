import React, {} from 'react';
import GenericModal from '../GenericModal';
import {
    Grid,
  } from "@material-ui/core"
import moment from 'moment'
import Logo from '../../assets/images/logo_uvm2.png'

import {
  BoxFilosofiaTitle,
  BoxFilosofia,
  ModalPlaneacionContenedor,
  ModalPlaneacionHeader,
  HeaderFecha,
  HeaderImg,
  HeaderTitle,
  ModalPlaneacionBody,
  BodySubTitle
} from './styles'
import jsPDF from 'jspdf'

const ModalPlaneacion = ({
    title,
    subtitle,
    loading,
    open,
    handleClose,
    handleSubmit,
    children,
    planeacion,
    generales,
    criterios
 }) => {

  function downloadPlaneacion(){
    try{
      var doc = new jsPDF('landscape')

      var img = new Image()
      img.src = Logo
  
      doc.addImage(img, 'JPEG', 115, 10, 70, 25)
      doc.setFontSize(12)
      doc.text(240, 15, moment().format('DD/MM/YYYY - hh:mm'))
      doc.setFontSize(26)
      doc.text(150, 47, 'Planeación', {align: 'center'})
  
      //Tabla Generales
      doc.setFontSize(12)
      doc.text(25, 57, 'DATOS GENERALES')
      let contadorAux = 0
      for(let i = 0; i < generales.length; i += 2){
        doc.text(25, 65+10*contadorAux, `${generales[i].name}: ${generales[i].content}`)
        doc.text(155, 65+10*contadorAux, `${generales[i+1].name}: ${generales[i+1].content}`)
        doc.rect(20, 60+10*contadorAux, 130, 10)
        doc.rect(150, 60+10*contadorAux, 130, 10)
        contadorAux++
      }
  
      // Headers
      doc.setFontSize(12)
      doc.text(25, 120, 'CONTENIDO DEL PROGRAMA')
      doc.text(25, 130, 'No.', {align: 'center', maxWidth: 20})
      doc.text(40, 130, 'Fecha', {align: 'center', maxWidth: 40})
      doc.text(100, 130, 'Temas', {align: 'center', maxWidth: 100})
      doc.text(225, 130, 'Bibliografía específica para la sesión', {align: 'center', maxWidth: 100})
  
      // Tabla
      doc.setFontSize(10)
      let alturaBase = 140
      let alturaFila = 10
      let contador = 0
      let lastPosition = 0
      for(let i = 0; i < planeacion.sesiones.length; i++){
        doc.text(25, alturaBase+alturaFila*(i-contador), `${i+1}`, {align: 'center', maxWidth: 20})
        doc.text(40, alturaBase+alturaFila*(i-contador), moment(planeacion.sesiones[i].fecha).format('DD/MM/YYYY'), {align: 'center', maxWidth: 40})
        
        let temas_string = getStringFromTemas(planeacion.sesiones[i].temas)
        let max_contador = 0
        if(temas_string.length > 80){
          doc.text(100, alturaBase+alturaFila*(i-contador)-5, getStringFromTemas(planeacion.sesiones[i].temas), {align: 'center', maxWidth: 100})
          max_contador = Math.round(temas_string.length/80)
        }else{
          doc.text(100, alturaBase+alturaFila*(i-contador), getStringFromTemas(planeacion.sesiones[i].temas), {align: 'center', maxWidth: 100})
        }

        let contador_biblio = 0
        let new_page = false
        
        planeacion.sesiones[i].bibliografias.forEach((bibli,index)=>{
          doc.text(225, alturaBase+alturaFila*(i-contador+(index-contador_biblio)), bibli['estructura']['name'], {align: 'center', maxWidth: 100})
          if(alturaBase+alturaFila*(i-contador+index) > 180){
            new_page = true
            doc.addPage()
            alturaBase = 5
            contador_biblio = index
          }
        })

        let heightRect = planeacion.sesiones[i].bibliografias.length + planeacion.sesiones[i].temas.length || 1
        doc.rect(20, 1+alturaBase+alturaFila*(i-contador-1), 130, 10*heightRect)
        doc.rect(30, 1+alturaBase+alturaFila*(i-contador-1), 0, 10*heightRect)
        doc.rect(50, 1+alturaBase+alturaFila*(i-contador-1), 0, 10*heightRect)
        doc.rect(150, 1+alturaBase+alturaFila*(i-contador-1), 130, 10*heightRect)
        contador -= planeacion.sesiones[i].bibliografias.length > max_contador ? planeacion.sesiones[i].bibliografias.length : max_contador
        if(new_page){
          contador = i - planeacion.sesiones[i].bibliografias.length
        }
        
        if(alturaBase+alturaFila*(i-contador) > 180){
          doc.addPage()
          alturaBase = 5
          contador = i
        }
        lastPosition = alturaBase+alturaFila*(i-contador)
      }
      
      if(criterios){
        lastPosition += 15
        doc.setFontSize(26)
        doc.text(150, lastPosition, `CRITERIOS EVALUACIÓN`, {align: 'center'})

        doc.setFontSize(12)
        alturaBase = lastPosition +=20
        alturaFila = 10
        contador = 0
        Object.keys(criterios).filter(reg => !reg.includes('FINAL')).forEach((key,i)=>{
          let reg = criterios[key]
          doc.text(25, alturaBase + alturaFila*(i-contador-1), key)
          contador -= 1
          doc.text(150, alturaBase + alturaFila*(i-contador-1), 'CRITERIOS EVALUACIÓN',{align: 'center'})
          doc.rect(20, alturaBase + 5 + alturaFila*(i-contador-2), 260, 10)
  
          if(alturaBase + alturaFila*(i-contador-1) > 180){
            doc.addPage()
            alturaBase = 10
            contador = i
          }
          contador -= 1
          Object.keys(reg['criterios_evaluacion']).forEach(key2=>{
            
            let reg2 = reg['criterios_evaluacion'][key2]
            doc.text(150, alturaBase + alturaFila*(i-contador-1), key2,{align: 'center'})
            doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 260, 10)
  
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
  
            contador -= 1
            doc.text(85, alturaBase + alturaFila*(i-contador-1), 'Criterios de evaluacion',{align: 'center'})
            doc.text(215, alturaBase + alturaFila*(i-contador-1), 'Porcentajes',{align: 'center'})
            doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
            doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
  
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
  
            contador-=1
            reg2['criterios'].forEach((criterio,index)=>{
              doc.text(25, alturaBase + alturaFila*(i-contador-1), criterio)
              doc.text(215, alturaBase + alturaFila*(i-contador-1), reg2['porcentajes'][index],{align: 'center'})
              doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
              doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
              contador-=1
              if(alturaBase + alturaFila*(i-contador-1) > 180){
                doc.addPage()
                alturaBase = 20
                contador = i
              }
            })
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
          })
  
          doc.text(85, alturaBase + alturaFila*(i-contador-1), 'Calificación parcial',{align: 'center'})
          doc.text(215, alturaBase + alturaFila*(i-contador-1), '100%',{align: 'center'})
          doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          contador-=1
  
          if(alturaBase + alturaFila*(i-contador-1) > 180){
            doc.addPage()
            alturaBase = 10
            contador = i
          }
          lastPosition = alturaBase + alturaFila*(i-contador-1)
        })
  
        alturaBase = lastPosition +=15
        alturaFila = 10
        contador = 0
  
        Object.keys(criterios).filter(reg => reg.includes('FINAL')).forEach((key,i)=>{
          let reg = criterios[key]
          doc.text(25, alturaBase + alturaFila*(i-contador-1), key)
          contador -= 1
          doc.setFillColor(155,155,155,1);
          doc.text(150, alturaBase + alturaFila*(i-contador-1), 'ESTRATEGIA DE EVALUACIÓN PARA EL LOGRO DE LA COMPETENCIA',{align: 'center'})
          doc.rect(20, alturaBase + 5 + alturaFila*(i-contador-2), 260, 10)
          contador -= 1
          Object.keys(reg['final']['estrategia']).filter(reg => reg != 'integracion').forEach(key2=>{
            let reg2 = reg['final']['estrategia'][key2]
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
  
           
            doc.text(85, alturaBase + alturaFila*(i-contador-1), key2.replaceAll('_',' ').toUpperCase(),{align: 'center'})
            doc.text(215, alturaBase + alturaFila*(i-contador-1), 'Porcentajes',{align: 'center'})
            doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
            doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
            contador -= 1
            reg2.forEach((criterio,index)=>{
              doc.text(25, alturaBase + alturaFila*(i-contador-1), criterio['criterio'])
              doc.text(215, alturaBase + alturaFila*(i-contador-1), criterio['porcentaje'],{align: 'center'})
              doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
              doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
              contador-=1
              if(alturaBase + alturaFila*(i-contador-1) > 180){
                doc.addPage()
                alturaBase = 20
                contador = i
              }
            })
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 10
              contador = i
            }
          })
          doc.text(85, alturaBase + alturaFila*(i-contador-1), 'Integración para el logro de la competencia'.toUpperCase(),{align: 'center'})
          doc.text(215, alturaBase + alturaFila*(i-contador-1), '100%',{align: 'center'})
          doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          contador-=1
          if(alturaBase + alturaFila*(i-contador-1) > 180){
            doc.addPage()
            alturaBase = 10
            contador = i
          }
          doc.text(150, alturaBase + alturaFila*(i-contador-1), 'EVALUACIONES',{align: 'center'})
          doc.rect(20, alturaBase + 5 + alturaFila*(i-contador-2), 260, 10)
          contador -= 1
          if(alturaBase + alturaFila*(i-contador-1) > 180){
            doc.addPage()
            alturaBase = 10
            contador = i
          }
          doc.text(85, alturaBase + alturaFila*(i-contador-1), 'EVALUACIÓN',{align: 'center'})
          doc.text(215, alturaBase + alturaFila*(i-contador-1), 'Porcentaje',{align: 'center'})
          doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
          contador -= 1
          if(alturaBase + alturaFila*(i-contador-1) > 180){
            doc.addPage()
            alturaBase = 10
            contador = i
          }
          reg['final']['integracion'].forEach(reg2=>{
            doc.text(20, alturaBase + alturaFila*(i-contador-1), reg2['evaluacion'])
            doc.text(215, alturaBase + alturaFila*(i-contador-1),  reg2['porcentaje'],{align: 'center'})
            doc.rect(20, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
            doc.rect(150, alturaBase  + 5 + alturaFila*(i-contador-2), 130, 10)
            contador -= 1
            if(alturaBase + alturaFila*(i-contador-1) > 180){
              doc.addPage()
              alturaBase = 20
              contador = i
            }
          })
  
        })  
      }
      
      //doc.save(`syllabus_planeacion_${context.state.item.clave}_${context.state.item.nombre_materia}_${context.state.teacherKey}_${context.state.item.profesor}_${moment().format('DD/MM/YYYY-hh:mm')}.pdf`)
      doc.save(`syllabus_planeacion_${generales[3].content}_${moment().format('DD/MM/YYYY-hh:mm')}.pdf`)
      

    }catch(e){
      console.log(e)
    }
  }
  function getStringFromTemas(temas){
    let str = ''
    for (let i = 0; i < temas.length; i++){
      let tema = temas[i].tema.replaceAll(/\t/g, '').split('\r\n')
      str += `${tema} `
    }
    return str
  }
  return (
    <>
      <GenericModal
        title = {title}
        subtitle = {subtitle}
        loading = {loading}
        open = {open}
        handleClose = {handleClose}
        handleSubmit = {downloadPlaneacion}
        children = {children}
        width = {'900px'}
        submitButtonTitle = "Descargar"
      >
        <ModalPlaneacionContenedor>
          <ModalPlaneacionHeader>
            <HeaderFecha>{moment().format('DD/MM/YYYY - hh:mm')}</HeaderFecha>
            <HeaderImg src={Logo} />
            <HeaderTitle>Planeación</HeaderTitle>
          </ModalPlaneacionHeader>
          <ModalPlaneacionBody>
            <div className='modalPlaneacionGenerales'>
              <BodySubTitle>Datos Generales</BodySubTitle>
              <Grid container>
                {generales.map(row => (
                  <Grid item xs={6}>
                    <BoxFilosofia>{row.name}: {row.content}</BoxFilosofia>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div className='modalPlaneacionPrograma'>
              <BodySubTitle>Contenido del Programa</BodySubTitle>
              <Grid container>
                <Grid item xs={1}>
                  <BoxFilosofiaTitle className='box-content-filosofia_title'>No.</BoxFilosofiaTitle>
                </Grid>
                <Grid item xs={3}>
                  <BoxFilosofiaTitle className='box-content-filosofia_title'>Fecha</BoxFilosofiaTitle>
                </Grid>
                <Grid item xs={4}>
                  <BoxFilosofiaTitle className='box-content-filosofia_title'>Tema</BoxFilosofiaTitle>
                </Grid>
                <Grid item xs={4}>
                  <BoxFilosofiaTitle className='box-content-filosofia_title'>Bibliografía específica para la sesión</BoxFilosofiaTitle>
                </Grid>
                {planeacion.sesiones.map((row, index) => (
                  <>
                    <Grid item xs={1}>
                      <BoxFilosofia>{index+1}</BoxFilosofia>
                    </Grid>
                    <Grid item xs={3}>
                      <BoxFilosofia>{moment(row.fecha).format('DD/MM/YYYY')}</BoxFilosofia>
                    </Grid>
                    <Grid item xs={4}>
                      <BoxFilosofia>{row.temas.map(tema => (`${tema.tema}. `))}</BoxFilosofia>
                    </Grid>
                    <Grid item xs={4}>
                      <BoxFilosofia>
                          {row.bibliografias.map(bibliografia => (
                            <p class="biblio-especifica">{bibliografia['estructura'] ? bibliografia['estructura']['name'] : bibliografia}.</p>
                          ))}
                      </BoxFilosofia>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </ModalPlaneacionBody>
        </ModalPlaneacionContenedor>
      </GenericModal>
    </>
  );
};

export default ModalPlaneacion;



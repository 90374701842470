import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AvatarIcon from '../../assets/images/AvatarIcon';

export const HeadBarContainer = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 200;
`;

export const HeaderIcons = styled.section`
  width: 100%;
  height: 50px;
  display: grid;
  background-color: #47cfac;
`;

export const IconsContainer = styled.section`
  width: 100px;
  height: auto;
  display: flex;
  justify-items: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-self: end;
  margin-right: 30px;
`;

export const HeadBar = styled.nav`
  display: flex;
  height: 96px;
  background-color: red;
  justify-content: space-between;

  @media screen and (max-width: 425px) {
    height: 65px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
  }

  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    height: 60px;
  }
`;

export const LogoContainer = styled.section`
  margin-left: 50px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 15px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    margin-left: 30px;
  }
`;

export const ProfileContainer = styled.section`
  margin-right: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 480px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 320px) {
    margin-right: 18px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    margin-right: 30px;
  }
`;

export const Img = styled.img`
  width: 220px;

  @media screen and (max-width: 768px) {
    width: 150px;
  }

  @media screen and (max-width: 480px) {
    width: 130px;
  }

  @media screen and (max-width: 375px) {
    width: 110px;
  }

  @media screen and (max-width: 320px) {
    width: 102px;
  }

  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 140px;
  }
`;

export const LinkStyled = styled(Link)`
  color: black;
  border-bottom: 1px solid black;
`;

export const Text = styled.p`
  color: white;
  margin: 5px;
  font-weight: 300;

  @media screen and (max-width: 480px) {
    margin: 2px;
    font-size: 13px;
    text-align: right;
  }

  @media screen and (max-width: 375px) {
    margin: 1px;
    font-size: 12px;
  }

  @media screen and (max-width: 320px) {
    margin: 1px;
    font-size: 11px;
  }

  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    margin: 1px;
    font-size: 13px;
  }
`;

export const AvatarContainer = styled.div`
  width: 60px;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    width: 50px;
  }
  @media screen and (max-width: 480px) {
    width: 45px;
  }
  @media screen and (max-width: 320px) {
    width: 40px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 40px;
  }
`;

export const AvatarIconStyled = styled(AvatarIcon)`
  cursor: pointer;
`;

import React from 'react';
import { connect } from 'react-redux';

import { setSelectedOptions, setAnswerData } from '../../actions';
import TableRow from './TableRow';
import { StyledTable, TH } from './styles';

const AbiertaTable = ({
  reactivos,
  questionIndex,
  asignaciones,
  agrupadores,
  answerData,
  setAnswerData,
  reactivo,
  index,
}) => {
  const handleChange = (asig, res) => {
    let agrupadorActual;
    agrupadores.forEach((agrupador) => {
      if (agrupador.reactivos.includes(reactivos[questionIndex])) {
        agrupadorActual = agrupador;
      }
    });

    const newRes = [];

    asignaciones.forEach((asignacion) => {
      if (
        asig.clave === asignacion.clave &&
        asig.nombre === asignacion.nombre &&
        asig.docente === asignacion.docente
      ) {
        newRes.push({
          id_respuesta: reactivos[questionIndex + index].opcions[0].id,
          id_agrupador: agrupadorActual.id,
          id_reactivo: reactivos[questionIndex + index].id,
          clave_materia: asignacion.clave,
          clave_docente: asignacion.clave_docente,
          id_asignacion: asignacion.id,
          id_student_assignment: asignacion.id_student_assignment,
          respuesta: res,
          tipo: 'abierta',
        });
      }
    });
    if (
      answerData.respuestas.find(
        (respuesta) =>
          respuesta.id_asignacion === asig.id &&
          respuesta.id_agrupador === agrupadorActual.id,
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) =>
          respuesta.clave_materia !== asig.clave ||
          respuesta.clave_docente !== asig.clave_docente ||
          respuesta.id_reactivo !== reactivos[questionIndex + index].id,
      );

      setAnswerData({
        ...answerData,
        respuestas: [...respuestasFiltradas, ...newRes],
      });
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [...answerData.respuestas, ...newRes],
      });
    }
  };

  const keys = [];
  const keysDuplicadas = [];
  const asignacionesVisibles = [];

  asignaciones.forEach((asignacion) => {
    const exists = keys.find(
      (key) =>
        JSON.stringify(key) ===
        JSON.stringify({
          clave: asignacion.clave,
          nombre: asignacion.nombre,
          docente: asignacion.docente,
        }),
    );
    if (!exists) {
      keys.push({
        clave: asignacion.clave,
        nombre: asignacion.nombre,
        docente: asignacion.docente,
      });

      asignacionesVisibles.push(asignacion);
    } else {
      keysDuplicadas.push({
        clave: asignacion.clave,
        nombre: asignacion.nombre,
        docente: asignacion.docente,
      });
    }
  });

  return (
    <StyledTable>
      <thead>
        <tr>
          <TH>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                textAlign: 'left',
              }}>
              Asignatura
            </p>
          </TH>
          <TH>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                textAlign: 'left',
              }}>
              Docente
            </p>
          </TH>
          <TH>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                textAlign: 'left',
              }}>
              Respuesta
            </p>
          </TH>
        </tr>
      </thead>
      <tbody>
        {asignacionesVisibles.map((asignacion, i) => {
          const isDuplicated = keysDuplicadas.find(
            (key) =>
              JSON.stringify(key) ===
              JSON.stringify({
                clave: asignacion.clave,
                nombre: asignacion.nombre,
                docente: asignacion.docente,
              }),
          );

          return (
            <TableRow
              isDuplicated={isDuplicated}
              key={asignacion.id}
              id={asignacion.id}
              index={i}
              dark={i % 2 === 0}
              asignatura={asignacion.nombre}
              docente={asignacion.docente}
              reactivo={reactivo}
              handleChange={(res) => handleChange(asignacion, res)}
            />
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const mapStateToProps = (state) => {
  return {
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
    asignaciones: state.asignaciones,
    answerData: state.answerData,
    agrupadores: state.agrupadores,
    renderedTables: state.renderedTables,
  };
};

const mapDispatchToProps = {
  setSelectedOptions,
  setAnswerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AbiertaTable);

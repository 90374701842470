import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { css } from 'glamor';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../Modal';
import { postRequest } from '../../utils/requester';

import {
  setAsignaciones,
  setAgrupadores,
  setEvalInfo,
  setReactivos,
  setAnswerData,
  setIsList,
} from '../../actions';

import {
  Container,
  StyledTable,
  TH,
  TD,
  ButtonsContainer,
  Button,
  Span,
} from './styles';

const Table = ({ evals }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();

  const matches = useMediaQuery('(min-width:600px)');

  const getType = (tipo) => {
    switch (tipo) {
      case 'Taller/Laboratorio':
        return 'Taller_Laboratorio';

      case 'Opinion_Estudiantil':
        return 'Opinion_Estudiantil';

      case 'Tipo ENE':
        return 'Tipo_ENE';

      case 'Encuestas':
        return 'Encuestas';

      case 'Evaluaciones':
        return 'Evaluaciones';
      case 'Evaluacion_Temprana':
        return 'Evaluacion_Temprana';
      default:
        return;
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 'realizada':
        return 'Realizada';

      case 'incompleta':
        return 'Incompleta';

      default:
        return 'Iniciar';
    }
  };

  const onClick = (id, path, fecha_fin, status, redirectUrl) => {
    if (moment.tz(fecha_fin, 'America/Mexico_City').isBefore()) {
      toast('Fecha de evaluación ya ha concluido', {
        className: css({
          background: '#e2e2e2',
          color: 'black',
        }),
        progressClassName: css({
          background: 'red',
        }),
      });
    } else if (status === 'realizada') {
      toast('Evaluación ya fue realizada', {
        className: css({
          background: '#e2e2e2',
          color: 'black',
        }),
        progressClassName: css({
          background: 'red',
        }),
      });
    } else if (status === 'incompleta') {
      toast('Evaluación incompleta', {
        className: css({
          background: '#e2e2e2',
          color: 'black',
        }),
        progressClassName: css({
          background: 'red',
        }),
      });
    } else {
      if (redirectUrl) {
        const win = window.open(redirectUrl, '_blank');
        win.focus();
      } else {
        history.push(`${path}/${id}`);
      }
    }
  };

  const getResults = async (id) => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      const data = await postRequest('/evaluacion/alumno/resultado', {
        id_evaluacion: id,
      });

      setTitle(data.d.titulo);
      setDescription(data.d.desempeño);
      setMessage(data.d.retroalimentación);
      console.log(data);
      console.log(evals);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <StyledTable>
          <thead>
            <tr>
              {matches ? (
                <>
                  <TH dark>Tipo de Evaluación</TH>
                  <TH>Nombre</TH>
                  <TH dark center width='140px'>
                    Fecha de vencimiento
                  </TH>
                  <TH center width='120px'>
                    Iniciar Encuesta
                  </TH>
                </>
              ) : (
                <>
                  <TH dark padding='0 0 0 15px'>
                    Evaluación
                  </TH>
                  <TH center width='200px'>
                    Fecha de vencimiento
                  </TH>
                  <TH dark center width='180px' padding='0 5px'>
                    Iniciar Encuesta
                  </TH>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {evals.length > 0 &&
              evals.map(
                (
                  { id, tipo, nombre, fecha_fin, status, url_encuesta_externa },
                  i,
                ) => (
                  <tr key={id}>
                    {matches ? (
                      <>
                        <TD dark odd={i % 2 === 0}>
                          {tipo}
                        </TD>
                        <TD odd={i % 2 === 0}>{nombre}</TD>
                        <TD dark center odd={i % 2 === 0} width='140px'>
                          {moment
                            .tz(fecha_fin, 'America/Mexico_City')
                            .isBefore()
                            ? 'vencida'
                            : moment
                                .tz(fecha_fin, 'America/Mexico_City')
                                .fromNow()}
                        </TD>
                        <TD center odd={i % 2 === 0} width='120px'>
                          <ButtonsContainer>
                            <Button
                              isDisabled={
                                moment
                                  .tz(fecha_fin, 'America/Mexico_City')
                                  .isBefore() || status !== 'pendiente'
                              }
                              onClick={() =>
                                onClick(
                                  id,
                                  `/instrucciones/${getType(tipo)}`,
                                  fecha_fin,
                                  status,
                                  url_encuesta_externa,
                                )
                              }>
                              {getStatus(status)}
                            </Button>
                            {status === 'realizada' &&
                              tipo === 'Evaluaciones' && (
                                <Button onClick={() => getResults(id)}>
                                  Resultados
                                </Button>
                              )}
                          </ButtonsContainer>
                        </TD>
                      </>
                    ) : (
                      <>
                        <TD dark odd={i % 2 === 0} padding='5px 0 5px 10px'>
                          {nombre}
                          <br />
                          <Span>{tipo}</Span>
                        </TD>
                        <TD center odd={i % 2 === 0} width='170px'>
                          {moment
                            .tz(fecha_fin, 'America/Mexico_City')
                            .isBefore()
                            ? 'vencida'
                            : moment
                                .tz(fecha_fin, 'America/Mexico_City')
                                .fromNow()}
                        </TD>
                        <TD dark center odd={i % 2 === 0} width='160px'>
                          <ButtonsContainer>
                            <Button
                              isDisabled={
                                moment
                                  .tz(fecha_fin, 'America/Mexico_City')
                                  .isBefore() || status !== 'pendiente'
                              }
                              onClick={() =>
                                onClick(
                                  id,
                                  `/instrucciones/${getType(tipo)}`,
                                  fecha_fin,
                                  status,
                                  url_encuesta_externa,
                                )
                              }>
                              {getStatus(status)}
                            </Button>
                            {status === 'realizada' &&
                              tipo === 'Evaluaciones' && (
                                <Button onClick={() => getResults(id)}>
                                  Resultados
                                </Button>
                              )}
                          </ButtonsContainer>
                        </TD>
                      </>
                    )}
                  </tr>
                ),
              )}
          </tbody>
          {evals.length === 0 && <p>No hay evaluaciones</p>}
        </StyledTable>
      </Container>
      <Modal
        type='results'
        title='Resultado de Evaluación'
        subtitle={title}
        description={description}
        message={message}
        open={isModalOpen}
        loading={loading}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  answerData: state.answerData,
  evals: state.evals,
});

const mapDispatchToProps = {
  setAsignaciones,
  setAgrupadores,
  setEvalInfo,
  setReactivos,
  setAnswerData,
  setIsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const ButtonContainerNext = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
`;

export const Button = styled.button`
  border: none;
  height: 35px;
  width: 125px;
  background-color: ${({ isNext }) => (isNext ? '#f00' : '#e2e2e2')};
  font-size: 20px;
  cursor: pointer;
  border-radius: 40px;
  color: ${({ isNext }) => isNext && '#fff'};
  ${({ userInfo }) =>
    userInfo &&
    css`
      position: absolute;
      right: 5px;
      top: 5px;
      background-color: #cdcdcd;
      height: 28px;
      width: 98px;
      font-size: 18px;
    `}
`;

import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ButtonExit } from '../Buttons';
import {
  UserBarContainer,
  LittleGrayBar,
  LittleRedBar,
  TextRow,
  FirstSection,
  SecondSection,
  MainBar,
  DataContiner,
  Text,
  TextContainer,
} from './styles';

const UserInfoFixed = ({ user, evalInfo, showFixed, rawType }) => {
  const location = useLocation();

  const mobileMatches = useMediaQuery('(max-width: 480px)');
  const tabletMatches = useMediaQuery('(max-width: 768px)');

  return (
    <UserBarContainer id='user-info-fixed' show={showFixed}>
      <LittleGrayBar></LittleGrayBar>
      <LittleRedBar></LittleRedBar>
      <MainBar>
        <FirstSection>
          {/* <TextRow> */}
          <TextContainer style={{ margin: '0 100px 0 0' }}>
            <Text>Nombre Alumno: </Text>
            <span>
              {user.nombre || ''} {user.apellido_paterno || ''}{' '}
              {user.apellido_materno || ''}
            </span>
          </TextContainer>
          {rawType !== '' ? (
            <TextContainer style={{ margin: '0' }}>
              <Text>Evaluacion: </Text>
              <span>{rawType}</span>
            </TextContainer>
          ) : null}
          {tabletMatches ? (
            <TextContainer style={{ margin: '0 50px 0 0' }}>
              <Text>Nombre programa: </Text>
              <span>{user.nombre_programa}</span>
            </TextContainer>
          ) : null}
          {tabletMatches ? null : (
            <TextContainer style={{ margin: '0 50px 0 0' }}>
              <Text>Nombre programa: </Text>
              <span>{user.nombre_programa}</span>
            </TextContainer>
          )}
          {/* </TextRow> */}
        </FirstSection>
        {(location.pathname === '/seleccion' && mobileMatches) ||
        (location.pathname === '/intervalo' && mobileMatches) ||
        (location.pathname === '/multiple' && mobileMatches) ||
        (location.pathname === '/presentacion' && mobileMatches) ||
        (location.pathname === '/envio' && mobileMatches) ? (
          <ButtonExit userInfo />
        ) : null}
      </MainBar>
    </UserBarContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    evalInfo: state.evalInfo,
    showFixed: state.showFixed,
    rawType: state.rawType,
  };
};

export default connect(mapStateToProps, null)(UserInfoFixed);

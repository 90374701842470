import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import 'moment/locale/es';
import 'react-toastify/dist/ReactToastify.css';


import {
  Container,
  StyledTable,
  TH,
  TD
} from './styles';

const GenericTable = ({ 
    headers,
    data,
    voidTable,
    actionsRows = [],
    headerBlanco
 }) => {

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Container>
        <StyledTable>
          <thead>
            <tr>
              {matches ? 
                headers.map((header,i)=>(
                  <TH 
                    dark = {(i%2 === 0)}
                    width = {header.width !== '' ? header.width:null}
                    center = {header.center}
                    headerBlanco = {headerBlanco}
                  >{header.name}</TH>
                ))
               : (
                headers.map(header=>(
                   <TH dark headerBlanco = {true}>{header.name}</TH>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((reg,i) => (
                  <tr key={i+400}>
                    {matches ? (
                        reg.map((cell,j)=>(
                            <TD
                              dark = {(j%2 === 0)}
                              odd = {i % 2 === 0}
                              width = {headers[j].width !== '' ? headers[j].width:null}
                              center = {headers[j].center}
                              actionRow = {actionsRows.includes(j)}
                            >{cell}</TD>
                        ))
                    ) : (
                        reg.map((cell,j)=>(
                            <TD
                             dark = {(j%2 === 0)}
                             odd = {i % 2 === 0}
                             width = {headers[j].width !== '' ? headers[j].width:null}
                             center = {headers[j].center}
                             actionRow = {actionsRows.includes(j)}
                            >{cell}</TD>
                        ))
                    )}
                  </tr>
                ),
              )}
          </tbody>
          {data.length === 0 && <p>{voidTable}</p>}
        </StyledTable>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);

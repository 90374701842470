import React from 'react';
import { connect } from 'react-redux';

import { CheckpointContainer, Point } from './styles';

const Checkpoint = ({ percent, number, questionIndex, agrupadores }) => {
  const reactivos = [];

  for (let i = 0; i < number; i++) {
    reactivos.push(...agrupadores[i].reactivos);
  }

  let isActive = false;

  if (questionIndex >= reactivos.length) {
    isActive = true;
  } else {
    isActive = false;
  }

  return (
    <CheckpointContainer percent={`${percent}%`}>
      <Point isActive={isActive}>{number + 1}</Point>
    </CheckpointContainer>
  );
};

const mapStateToProps = (state) => ({
  agrupadores: state.agrupadores,
  questionIndex: state.questionIndex,
  evalInfo: state.evalInfo,
  isList: state.isList,
});

export default connect(mapStateToProps)(Checkpoint);

import React from 'react';
import { connect } from 'react-redux';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { TD, FirstOptionsText } from './styles';

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
    padding: 0,
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const TableRow = ({
  index,
  id,
  asignatura,
  docente,
  handleClick,
  answerData,
  options,
  reactivo,
  questionIndex,
  agrupadores,
  reactivos,
  isDuplicated,
}) => {
  const respuestaFiltrada = answerData.respuestas.find(
    (respuesta) =>
      respuesta.id_asignacion === id && reactivo.id === respuesta.id_reactivo,
  );

  let agrupadorActual;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador;
    }
  });

  return (
    <tr>
      <TD
        isDuplicated={isDuplicated}
        odd={index % 2 === 0}
        style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
        <FirstOptionsText>{asignatura}</FirstOptionsText>
      </TD>
      <TD style={{ paddingLeft: '11px' }} odd={index % 2 === 0}>
        <FirstOptionsText>{docente}</FirstOptionsText>
      </TD>
      {options.map((option, i) => {
        const handleChange = () => {
          handleClick(option.nombre, i, option.id);
        };

        return (
          <TD
            onClick={handleChange}
            key={option.id}
            odd={index % 2 === 0}
            dark={i % 2 === 0}>
            <RedRadio
              checked={
                respuestaFiltrada &&
                respuestaFiltrada.valor_respuesta === i &&
                respuestaFiltrada.id_agrupador === agrupadorActual.id
              }
              onChange={handleChange}
              size='small'
            />
          </TD>
        );
      })}
    </tr>
  );
};

const mapStateToProps = (state) => ({
  answerData: state.answerData,
  reactivos: state.reactivos,
  questionIndex: state.questionIndex,
  agrupadores: state.agrupadores,
});

export default connect(mapStateToProps)(TableRow);

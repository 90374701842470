import React from 'react';
import { connect } from 'react-redux';

import { setAnswerData } from '../../actions';

import { RangeContainer, Bar, Radius, Color, Number } from './styles';

const IntervaloItem = ({
  answerData,
  asignaciones,
  agrupadores,
  setAnswerData,
  reactivo,
}) => {
  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivo),
  )[0];

  const handleClick = (res, val, id_res) => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id,
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) =>
          respuesta.id_reactivo !== reactivo.id ||
          respuesta.id_agrupador !== agrupadorActual.id,
      );

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      });
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      });
    }
  };

  const respuestaFiltrada = answerData.respuestas.find(
    (respuesta) => reactivo.id === respuesta.id_reactivo,
  );

  return (
    <RangeContainer>
      {reactivo.opcions.map(({ nombre, id }, i) => (
        <React.Fragment key={i}>
          <Radius onClick={() => handleClick(nombre, i, id)}>
            <Color
              selected={
                respuestaFiltrada && respuestaFiltrada.valor_respuesta >= i
              }
            />
            <Number>{nombre}</Number>
          </Radius>
          {reactivo.opcions.length > i + 1 && (
            <Bar
              selected={
                respuestaFiltrada && respuestaFiltrada.valor_respuesta > i
              }
            />
          )}
        </React.Fragment>
      ))}
    </RangeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    answerData: state.answerData,
    asignaciones: state.asignaciones,
    agrupadores: state.agrupadores,
    isList: state.isList,
  };
};

const mapDispatchToProps = {
  setAnswerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntervaloItem);

import styled from 'styled-components';

export const InputContainer = styled.div`
  margin: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 8em;
  position: relative;

  @media screen and (max-width: 1366px) {
    margin-bottom: 6em;
  }

  @media screen and (max-width: 768px) {
    margin: 38px 0 100px;
    flex-wrap: wrap;
  }
  padding-left: env(safe-area-inset-left);
`;

export const Wrapper = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }

  @media screen and (max-width: 1366px) {
    grid-template-rows: 1fr 1fr;
  }
`;

export const Text = styled.p`
  font-size: 21px;
  margin: 0 10px 0 0;
  @media screen and (max-width: 768px) {
    margin: 0 10px 10px 0;
  }
`;

export const Input = styled.input`
  border: none;
  background-color: #e2e2e2;
  width: 100%;
  font-size: 21px;
  height: 33px;

  &:placeholder-shown {
    font-size: 21px;
  }
`;

export const CounterText = styled.span`
  position: absolute;
  right: 0;
  top: -23px;
`;

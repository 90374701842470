import React from 'react';

function AvatarIcon(props) {
  return (
    <svg viewBox='0 0 76.82 76.82' {...props}>
      <defs>
        <style>{'.AvatarIcon_svg__cls-1{fill:#FFFFFF}'}</style>
      </defs>
      <g id='AvatarIcon_svg__Layer_2' data-name='Layer 2'>
        <g id='AvatarIcon_svg__Capa_1' data-name='Capa 1'>
          <path
            className='AvatarIcon_svg__cls-1'
            d='M38.43 37.87a6.76 6.76 0 004.78-11.55 6.76 6.76 0 10-9.56 9.56 6.55 6.55 0 004.78 1.99zM50.26 45.94a16.8 16.8 0 00-.26-1.78 14 14 0 00-.44-1.78 8.77 8.77 0 00-.73-1.66 6.25 6.25 0 00-1.11-1.44 4.87 4.87 0 00-1.59-1 5.5 5.5 0 00-2-.37 2.06 2.06 0 00-1.1.47l-1.14.74a6.57 6.57 0 01-1.48.65 5.75 5.75 0 01-3.62 0 6.55 6.55 0 01-1.48-.65l-1.15-.74a2.05 2.05 0 00-1.1-.47 5.48 5.48 0 00-2 .37 4.87 4.87 0 00-1.59 1 6.28 6.28 0 00-1.11 1.44 8.86 8.86 0 00-.73 1.66 14 14 0 00-.44 1.78 16.43 16.43 0 00-.23 1.78c0 .54-.06 1.09-.06 1.66a4.66 4.66 0 001.39 3.53 5 5 0 003.57 1.3h13.51a5 5 0 003.57-1.3 4.66 4.66 0 001.38-3.53c0-.6-.02-1.13-.06-1.66z'
          />
          <circle
            cx={38.41}
            cy={38.41}
            r={37.41}
            fill='none'
            stroke='#FFF'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  );
}

export default AvatarIcon;

import React, { useEffect, useState, useRef } from 'react'
import { MemoryRouter as Router, Route } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'

import { setLoading, setUser, setEvals, setAnswerData } from './actions'

import { getRequest, postLogin } from './utils/requester'

import Home from './pages/Home'
import Login from './pages/Login'
import Instrucciones from './pages/Instrucciones'
import Presentacion from './pages/Presentacion'
import Seleccion from './pages/Seleccion'
import PreguntaAbierta from './pages/Abierta'
import PreguntaOpcionMultiple from './pages/OpcionMultiple'
import Intervalo from './pages/Intervalo'
import Multiple from './pages/Multiple'
import EnvioResultados from './pages/EnvioResultados'
import FinEvaluacion from './pages/FinEvaluacion'
import DetalleSyllabus from './pages/DetalleSyllabus'
import Layout from './components/Layout'
import UserInfo from './components/UserInfo'
import UserInfoFixed from './components/UserInfoFixed'
import Timer from './components/Timer'
import { GlobalStyles } from './styles/GlobalStyles'
import { PulseLoader } from './components/PulseLoader'

toast.configure()

const AppContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: filter 0.17s ease-in-out;
  ${({ isLoading }) =>
    isLoading &&
    css`
      filter: blur(3px);
    `}
`

const App = ({
  setLoading,
  isLoading,
  setUser,
  setAuth,
  setEvals,
  setAnswerData,
  tokenKey,
  duration,
}) => {
  const [isLoged, setIsLoged] = useState(false)

  const urlParameters = new URLSearchParams(window.location.search).get('data')
  const isAuth = window.localStorage.getItem(tokenKey) || urlParameters

  useEffect(() => {
    getUserInfo()

    // eslint-disable-next-line
  }, [isAuth])

  async function login() {
    setLoading(true)
    try {
      const data = await getRequest(
        '?plt=evaluacion&token=&rol=estudiante',
        false,
        true
      )
      if (data.s === 'OK') {
        window.location.href = data['d']['data']['url_login']
      } else {
        setLoading(false)
        toast.error(data.m)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const getUserInfo = async () => {
    const token = window.localStorage.getItem(tokenKey)
    if (token) {
      let myUrl = window.location.href
      let inicioParametros = myUrl.indexOf('?')
      let newUrl = myUrl.substr(0, inicioParametros)
      window.history.pushState('obj', 'newtitle', newUrl)
      setLoading(true)
      try {
        const response = await getRequest('/login/detalle')
        if (response.s === 'OK') {
          if (
            !(
              response.d.rol === 'estudiante' ||
              response.d.rol === 'administrador'
            )
          ) {
            toast.error('Rol no tiene acceso')
            setLoading(false)
            window.localStorage.removeItem(tokenKey)
            return
          }
          setUser(response.d)
          setAnswerData({
            id_user: response.d.id,
            clave_user: response.d.clave,
            respuestas: [],
          })
        } else {
          window.localStorage.removeItem(tokenKey)
          setUser({})
        }
        setIsLoged(true)
        setLoading(false)
      } catch (error) {
        window.localStorage.removeItem(tokenKey)
        setUser({})
        setLoading(false)
      }
    } else {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const code = urlParams.get('code')
      const mode = urlParams.get('mode')
      const mail = urlParams.get('mail')
      if (code !== null) {
        setLoading(true)
        try {
          let data;
          if (mode !== null && mail !== null){
              data = await getRequest(
                `?plt=evaluacion&code=${code}&rol=estudiante&mode=${mode}&mail=${mail}`,
                false,
                true
              )
          }else{
              data = await getRequest(
                `?plt=evaluacion&code=${code}&rol=estudiante`,
                false,
                true
              )
          }

          if (data.s === 'OK') {
            window.localStorage.setItem(tokenKey, data['d']['data']['token'])
            window.location.reload()
          } else {
            toast.error(data.m)
            window.localStorage.removeItem(tokenKey)
            let myUrl = window.location.href
            let inicioParametros = myUrl.indexOf('?')
            let newUrl = myUrl.substr(0, inicioParametros)
            window.history.pushState('obj', 'newtitle', newUrl)
            setLoading(false)
          }
        } catch (error) {
          console.log(error)
          let myUrl = window.location.href
          let inicioParametros = myUrl.indexOf('?')
          let newUrl = myUrl.substr(0, inicioParametros)

          window.history.pushState('obj', 'newtitle', newUrl)
          setTimeout(() => {
            window.location.reload()
          }, 2000)

          window.localStorage.removeItem(tokenKey)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <GlobalStyles />
      {isLoading && isLoged && <PulseLoader />}
      {isAuth && isLoged ? (
        <Router>
          <AppContainer isLoading={isLoading}>
            <Layout>
              <UserInfo />
              <UserInfoFixed />
              {duration && <Timer />}
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/instrucciones/:type/:id"
                component={Instrucciones}
              />
              <Route exact path="/presentacion" component={Presentacion} />
              <Route exact path="/seleccion" component={Seleccion} />
              <Route exact path="/abierta" component={PreguntaAbierta} />
              <Route
                exact
                path="/opcion-multiple"
                component={PreguntaOpcionMultiple}
              />
              <Route exact path="/intervalo" component={Intervalo} />
              <Route exact path="/multiple" component={Multiple} />
              <Route exact path="/envio" component={EnvioResultados} />
              <Route exact path="/fin" component={FinEvaluacion} />
              <Route
                exact
                path="/detalle-syllabus"
                component={DetalleSyllabus}
              />
            </Layout>
          </AppContainer>
        </Router>
      ) : (
        <Layout hideHeader>
          <Login onSubmit={login} />
        </Layout>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    tokenKey: state.tokenKey,
    duration: state.duration,
  }
}

const mapDispatchToProps = {
  setLoading,
  setUser,
  setEvals,
  setAnswerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 445px);
  overflow-y: auto;
  @media screen and (min-width: 426px) {
    max-height: calc(100vh - 475px);
  }

  @media screen and (min-width: 600px) {
    max-height: calc(100vh - 470px);
  }

  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 460px);
  }

  @media screen and (min-width: 1025px) {
    max-height: calc(100vh - 455px);
  }

  @media screen and (min-width: 1165px) {
    max-height: calc(100vh - 445px);
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TH = styled.th`
  background-color: ${({ dark }) => (dark ? '#EF001E' : '#FF2A01')};
  width: ${({ width }) => (width ? width : '245px')};
  height: 36px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  padding: 2px 20px 2px 14px;
  position: sticky;
  top: 0;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 2px 10px 2px 7px;
  }

  @media screen and (max-width: 600px) {
    padding: ${({ padding }) => (padding ? padding : 0)};
    font-size: 15px;
  }

  @media screen and (max-width: 375px) {
    font-size: 13px;
  }
`;

export const TD = styled.td`
  width: ${({ width }) => (width ? width : '245px')};
  font-size: 16px;
  padding: 4px 24px 4px 16px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  height: 20px;
  ${({ odd }) =>
    odd
      ? css`
          background-color: ${({ dark }) => (dark ? '#C2C2C2' : '#DFDFDF')};
        `
      : css`
          background-color: ${({ dark }) => (dark ? '#DEDEDE' : '#FFFFFF')};
        `};

  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }

  @media screen and (max-width: 599px) {
    font-size: 15px;
    padding: ${({ padding }) => (padding ? padding : 0)};
  }

  @media screen and (max-width: 425px) {
    font-size: 13px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Button = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? '#6e6e6e' : '#191919')};
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 20px;
  cursor: pointer;
  font-size: 15px;
  border: none;

  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`;

export const Span = styled.span`
  font-size: 12px;

  @media screen and (max-width: 425px) {
    font-size: 10px;
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';

import {
  setModal,
  setQuestionIndex,
  setEvalInfo,
  setIndexHistory,
} from '../../actions';

import Modal from '../Modal';
import { postRequest } from '../../utils/requester';
import { ButtonContainer, ButtonContainerNext, Button } from './styles';

const ButtonExitComponent = ({ isModalOpen, setModal, userInfo }) => {
  return (
    <>
      <ButtonContainer userInfo={userInfo}>
        <Button userInfo={userInfo} onClick={(e) => setModal(true)}>
          Salir
        </Button>
      </ButtonContainer>
      <Modal
        type='exit-eval'
        open={isModalOpen}
        title='Salir de la evaluación'
        description='¿Está seguro que desea salir de la evaluación?'
        handleClose={(e) => setModal(false)}
      />
    </>
  );
};

const ButtonNextComponent = ({
  questionIndex,
  reactivos,
  agrupadores,
  canContinue,
  answerData,
  type,
  setQuestionIndex,
  isList,
  setIndexHistory,
  indexHistory,
  renderedTables,
}) => {
  const history = useHistory();

  let agrupadorActual;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador;
    }
  });

  let agrupadorSiguiente;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex + 1])) {
      agrupadorSiguiente = agrupador;
    }
  });

  let reactivosActuales = [];
  for (let i = 0; i < agrupadores.length; i++) {
    reactivosActuales.push(...agrupadores[i].reactivos);
    if (JSON.stringify(agrupadores[i]) === JSON.stringify(agrupadorActual)) {
      break;
    }
  }

  const sendAnswers = () => {
    const respuestas = answerData.respuestas.map((respuesta) => {
      if (answerData.tipo !== 'Opinion_Estudiantil') {
        return {
          id_respuesta: respuesta.id_respuesta,
          id_agrupador: respuesta.id_agrupador,
          id_reactivo: respuesta.id_reactivo,
          id_asignacion: respuesta.id_asignacion,
          id_student_assignment: respuesta.id_student_assignment,
          respuesta: respuesta.respuesta,
          tipo: respuesta.tipo,
        };
      }
      return {
        id_respuesta: respuesta.id_respuesta,
        id_agrupador: respuesta.id_agrupador,
        id_reactivo: respuesta.id_reactivo,
        clave_materia: respuesta.clave_materia,
        clave_docente: respuesta.clave_docente,
        id_asignacion: respuesta.id_asignacion,
        id_student_assignment: respuesta.id_student_assignment,
        respuesta: respuesta.respuesta,
        tipo: respuesta.tipo,
      };
    });

    const answers = respuestas.slice(
      questionIndex,
      questionIndex + renderedTables,
    );

    const newAnswerData = {
      ...answerData,
      respuestas: answers,
    };

    try {
      postRequest('/evaluacion/responder', newAnswerData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    if (!canContinue) {
      toast('Para continuar se debe responder', {
        className: css({
          background: '#e2e2e2',
          color: 'black',
        }),
        progressClassName: css({
          background: 'red',
        }),
      });
    } else {
      if (isList) {
        type === 'Evaluaciones' && sendAnswers();

        if (questionIndex + renderedTables < reactivos.length) {
          if (questionIndex + 5 < reactivosActuales.length) {
            setQuestionIndex(questionIndex + 5);
            setIndexHistory([...indexHistory, 5]);
            if (
              JSON.stringify(agrupadorActual) !==
              JSON.stringify(agrupadorSiguiente)
            ) {
              history.push('/presentacion');
            } else {
              history.push('/multiple');
            }
          } else if (reactivosActuales.length - questionIndex === 0) {
            setQuestionIndex(questionIndex + 1);
            setIndexHistory([...indexHistory, 1]);
            history.push('/presentacion');
          } else {
            setQuestionIndex(
              questionIndex + (reactivosActuales.length - questionIndex),
            );
            setIndexHistory([...indexHistory, renderedTables]);
            history.push('/presentacion');
          }
        } else {
          setQuestionIndex(questionIndex + renderedTables);
          setIndexHistory([...indexHistory, renderedTables]);
          history.push('/envio');
        }
      } else {
        if (questionIndex + 1 < reactivos.length) {
          setQuestionIndex(questionIndex + 1);
          if (
            JSON.stringify(agrupadorActual) !==
            JSON.stringify(agrupadorSiguiente)
          ) {
            history.push('/presentacion');
          } else {
            if (reactivos[questionIndex + 1].tipo === 'seleccion simple') {
              history.push('/seleccion');
            } else if (
              reactivos[questionIndex + 1].tipo === 'seleccion multiple'
            ) {
              history.push('/opcion-multiple');
            } else {
              history.push(`/${reactivos[questionIndex + 1].tipo}`);
            }
          }
        } else {
          setQuestionIndex(questionIndex + 1);
          history.push('/envio');
        }
      }
    }
  };

  return (
    <ButtonContainerNext>
      <Button isNext onClick={handleNext}>
        Siguiente
      </Button>
    </ButtonContainerNext>
  );
};

const mapStateToPropsExit = (state) => {
  return {
    isModalOpen: state.isModalOpen,
  };
};

const mapDispatchToPropsExit = {
  setModal,
};

const mapStateToPropsNext = (state) => {
  return {
    questionIndex: state.questionIndex,
    reactivos: state.reactivos,
    agrupadores: state.agrupadores,
    answerData: state.answerData,
    type: state.type,
    isList: state.isList,
    indexHistory: state.indexHistory,
    renderedTables: state.renderedTables,
  };
};

const mapDispatchToPropsNext = {
  setModal,
  setQuestionIndex,
  setEvalInfo,
  setIndexHistory,
};

export const ButtonExit = connect(
  mapStateToPropsExit,
  mapDispatchToPropsExit,
)(ButtonExitComponent);

export const ButtonNext = connect(
  mapStateToPropsNext,
  mapDispatchToPropsNext,
)(ButtonNextComponent);

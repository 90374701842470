import React from 'react'
import { connect } from 'react-redux'
import { UserForm } from '../components/UserForm'

export const Login = ({ isLoading, onSubmit }) => {
  return <UserForm onSubmit={onSubmit} loading={isLoading} />
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  }
}

export default connect(mapStateToProps)(Login)

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        user: payload,
      };

    case 'SET_MODAL':
      return {
        ...state,
        isModalOpen: payload,
      };

    case 'SET_RAW_TYPE':
      return {
        ...state,
        rawType: payload,
      };

    case 'SET_TYPE':
      return {
        ...state,
        type: payload,
      };

    case 'SET_AUTH_MODAL':
      return {
        ...state,
        isAuthModalOpen: payload,
      };

    case 'SET_VERSION':
      return {
        ...state,
        version: payload,
      };

    case 'SET_LOADING':
      return {
        ...state,
        isLoading: payload,
      };

    case 'SET_LOADING_RESPUESTA':
      return {
        ...state,
        loadingRespuesta: payload,
      };

    case 'SET_AUTH':
      return {
        ...state,
        isAuth: payload,
      };

    case 'CLEAR_ANSWER_DATA':
      return {
        ...state,
        answerData: {
          id_user: state.answerData.id_user,
          clave_user: state.answerData.clave_user,
          respuestas: [],
        },
      };

    case 'CLEAR_EVAL_INFO':
      return {
        ...state,
        evalInfo: null,
      };

    case 'SET_EVALS':
      return {
        ...state,
        evals: payload,
      };

    case 'SET_SYLLABUS':
      return {
        ...state,
        syllabus: payload,
      };

    case 'SET_DETALLE_SYLLABUS':
      return {
        ...state,
        detalleSyllabus: payload,
      };
    case 'SET_ASIGNACIONES':
      return {
        ...state,
        asignaciones: payload,
      };

    case 'SET_AGRUPADORES':
      return {
        ...state,
        agrupadores: payload,
      };

    case 'SET_EVAL_INFO':
      return {
        ...state,
        evalInfo: payload,
      };

    case 'SET_QUESTION_INDEX':
      return {
        ...state,
        questionIndex: payload,
      };

    case 'SET_REACTIVOS':
      return {
        ...state,
        reactivos: payload,
      };

    case 'SET_ANSWER_DATA':
      return {
        ...state,
        answerData: payload,
      };

    case 'SET_IS_LIST':
      return {
        ...state,
        isList: payload,
      };

    case 'SET_RENDERED_TABLES':
      return {
        ...state,
        renderedTables: payload,
      };

    case 'SET_ERROR_EVALUACION':
      return {
        ...state,
        errorEvaluacion: payload,
      };

    case 'SET_SHOW_FIXED':
      return {
        ...state,
        showFixed: payload,
      };

    case 'SET_TOKEN_KEY':
      return {
        ...state,
        tokenKey: payload,
      };

    case 'SET_INDEX_HISTORY':
      return {
        ...state,
        indexHistory: payload,
      };

    case 'SET_DURATION':
      return {
        ...state,
        duration: payload,
      };

    default:
      return state;
  }
};

export default reducer;

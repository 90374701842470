import styled from 'styled-components';

export const BounceLoaderContainer = styled.div`
  z-index: 500;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  position: fixed;
`;

import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: relative;
  background-color: #fff;
  outline: 0;
  max-width: 530px;
  width: 90%;
  max-width: ${({ width }) => width};
  min-height: 250px;
`;

export const ModalAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  margin: 0;
  background-color: #ff0000;
  padding: 9px 20px;
`;

export const ModalContainer = styled.div`
  text-align: center;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 150px;
  padding: 0px 20px 44px;
`;

export const LoaderContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Bold = styled.p`
  font-weight: 700;
  font-size: 22px;
  text-align: left;
`;

export const Text = styled.p`
  font-size: 22px;
  margin: 10px 0;

  @media screen and (max-width: 480px) {
    font-size: 1.4em;
  }
`;

export const H3 = styled.h3`
  margin: 0;
  color: white;
  font-size: 1.75em;
  font-weight: 400;
`;

export const ButtonsContainer = styled.div`
  margin: 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const Button = styled.button`
  background-color: ${({ color }) => color};
  margin: 0 7px;
  border: none;
  border-radius: 15px;
  width: 108px;
  height: 24px;
  color: white;
  cursor: pointer;
  font-size: 16px;
`;

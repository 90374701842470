import styled from 'styled-components';

export const Container = styled.footer`
  background-color: #d8d8d8;
  width: 100%;
  display: block;
  padding: 5px 20px;
  font-size: 13px;
  height: 25px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 200;
  padding-bottom: env(safe-area-inset-bottom);

  @media screen and (orientation: landscape) and (min-device-width: 812px) and (max-device-width: 375px) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
`;

export const Floater = styled.span`
  float: ${({ float }) => float};
`;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setAnswerData } from '../actions';
import Progress from '../components/Progress';
import { ButtonExit } from '../components/Buttons';

const PreguntasContainer = styled.div`
  margin: 1rem 3rem 0;
  margin-bottom: 0px;
  height: 70%;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
  padding-left: env(safe-area-inset-left);
`;

const Wrapper = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }

  @media screen and (max-width: 1366px) {
    grid-template-rows: 1fr 1fr;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6em;
  width: 60%;

  @media screen and (min-width: 769px) and (max-width: 1366px) {
    margin-bottom: 4em;
  }
`;
const Name = styled.p`
  font-size: 24px;
  text-align: center;
`;

const Question = styled.p`
  font-size: 24px;
`;

const FormContainer = styled.div`
  max-height: calc(100% - 330px);
  overflow: auto;
  @media screen and (max-width: 1366px) {
    margin: 0 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    max-height: 340px;
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 25px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-bottom: calc(5px + env(safe-area-inset-bottom));
  }
`;

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 91%;
    margin: 0 auto;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
`;

export const SquareCheck = styled.label`
  width: ${({ size }) => (size ? size : '25px')};
  height: ${({ size }) => (size ? size : '25px')};
  border: 2px solid gray;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? 'red' : 'white')};
`;

export const TextLabel = styled.p`
  text-align: center;
`;

const PreguntaOpcionMultiple = ({
  agrupadores,
  questionIndex,
  reactivos,
  answerData,
  setAnswerData,
  asignaciones,
}) => {
  const [canContinue, setCanContinue] = useState(false);

  const matches = useMediaQuery('(max-width:480px)');

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivos[questionIndex]),
  )[0];

  const respuestaActual = answerData.respuestas.find(
    (respuesta) => respuesta.id_reactivo === reactivos[questionIndex].id,
  );

  const handleChange = (res, id_res) => {
    if (respuestaActual) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivos[questionIndex].id,
      );

      const isIncluded = respuestaActual.respuestas.find(
        (res) => res.id_respuesta === id_res,
      );

      if (isIncluded) {
        const newRes = respuestaActual.respuestas.filter(
          (res) => res.id_respuesta !== id_res,
        );
        setAnswerData({
          ...answerData,
          respuestas: [
            ...respuestasFiltradas,
            {
              ...respuestaActual,
              respuestas: [...newRes],
            },
          ],
        });
      } else {
        setAnswerData({
          ...answerData,
          respuestas: [
            ...respuestasFiltradas,
            {
              ...respuestaActual,
              respuestas: [
                ...respuestaActual.respuestas,
                {
                  id_respuesta: id_res,
                  respuesta: res,
                },
              ],
            },
          ],
        });
      }
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuestas: [
              {
                id_respuesta: id_res,
                respuesta: res,
              },
            ],
            tipo: 'seleccion multiple',
          },
        ],
      });
    }
  };

  let ids = [];

  if (respuestaActual) {
    respuestaActual.respuestas.forEach((res) => {
      ids = [...ids, res.id_respuesta];
    });
  }

  if (respuestaActual) {
    const newCanContinue = respuestaActual.respuestas.length > 0;
    canContinue !== newCanContinue && setCanContinue(newCanContinue);
  }

  return (
    <>
      <PreguntasContainer>
        <Name>{`${reactivos[questionIndex].numero}.${reactivos[questionIndex].nombre}`}</Name>
        <Question>{`${reactivos[questionIndex].pregunta}`}</Question>
        <FormContainer>
          <OptionsContainer>
            {reactivos[questionIndex].opcions.map(({ id, nombre }) => (
              <Option key={id}>
                {nombre && <TextLabel>{nombre}</TextLabel>}
                <input
                  style={{ display: 'none' }}
                  type='checkbox'
                  onChange={() => handleChange(nombre, id)}
                  id={`checkbox-${id}`}
                />
                <SquareCheck
                  checked={ids.includes(id)}
                  htmlFor={`checkbox-${id}`}></SquareCheck>
              </Option>
            ))}
          </OptionsContainer>
        </FormContainer>
      </PreguntasContainer>

      <ControlsWrapper>
        <Controls>
          {!matches && <ButtonExit />}
          <Progress canContinue={canContinue} />
        </Controls>
      </ControlsWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    questionIndex: state.questionIndex,
    reactivos: state.reactivos,
    answerData: state.answerData,
    asignaciones: state.asignaciones,
  };
};

const mapDispatchToProps = {
  setAnswerData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreguntaOpcionMultiple);

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BotonSafeKey from '../../assets/images/boton_safekey.png';
import { LINK_ADMINISTRACION_SIEE } from '../../utils/requester';

import {
  FormPage,
  FormContainer,
  Form,
  ButtonContainer,
  Button,
  Image,
  Title,
  ExternalLink,
} from './styles';

import logo from '../../assets/images/logo_uvm.png';

export const UserForm = ({ onSubmit, loading }) => {
  let loaderSize;

  const tablet = useMediaQuery('(max-width:768px)');
  const mobile = useMediaQuery('(max-width:425px)');

  if (!tablet && !mobile) {
    loaderSize = 40;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <FormPage>
      <FormContainer>
        <Image src={logo} alt='logo-uvm' />
        <Title>SIEE-Acceso Estudiantes</Title>
        <Form onSubmit={handleSubmit}>
          <ButtonContainer>
            {loading ? (
              <div className='loader-container'>
                <CircularProgress size={40} color='inherit' />
              </div>
            ) : (
              <Button type='submit' disabled={loading}>
                <img
                  src={BotonSafeKey}
                  alt='boton_safekey'
                  style={{ height: '130%' }}
                />
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </FormContainer>
      <ExternalLink href={LINK_ADMINISTRACION_SIEE} target='_blank'>
        Administración-SIEE
      </ExternalLink>
    </FormPage>
  );
};

import React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Progress from '../components/Progress';
import { ButtonExit } from '../components/Buttons';

const Container = styled.div`
  margin: 1rem 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
`;

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  margin-bottom: 25px;
  padding-bottom: 10px;
`;

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
`;

const Text = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  position:absolute;
  top:40%;
  margin: 3em 5em 4em;
  @media screen and (max-width: 768px) {
    margin: 1em 0em 4em;
  }
`;

const EnvioResultados = () => {
  const matches = useMediaQuery('(max-width: 480px)');

  return (
    <>
      <Container>
        <Text>
          Has llegado al final de la evaluación, al presionar el botón de enviar tus respuesta serán guardadas
        </Text>
      </Container>

      <ControlsWrapper>
        <Controls>
          {!matches && <ButtonExit />}
          <Progress canContinue enviar />
        </Controls>
      </ControlsWrapper>
    </>
  );
};

export default EnvioResultados;

import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  ModalWrapper,
  Title,
  ModalContainer,
  LoaderContainer,
  H3,
  ModalAligner,
  Bold,
  Text,
  ButtonsContainer,
  Button,
} from './styles';

const GenericModal = ({
  title,
  subtitle,
  loading,
  open,
  handleClose,
  handleSubmit,
  children,
  width,
  submitButtonTitle
}) => {

  return (
    <Modal open={open}
    >
      <ModalAligner>
        <ModalWrapper
          width = {width}
        >
          <Title>
            <H3>{title}</H3>
          </Title>
          {loading ? (
            <LoaderContainer>
              <CircularProgress size={60} color='secondary' />
            </LoaderContainer>
          ) : (
            <>
              <ModalContainer>
                {children}
                <ButtonsContainer>            
                  <Button color='#878787' onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button color='#000' onClick={handleSubmit}>
                    {
                      submitButtonTitle || 'Aceptar'
                    }
                  </Button>
                </ButtonsContainer>
              </ModalContainer>
            </>
          )}
        </ModalWrapper>
      </ModalAligner>
    </Modal>
  );
};

export default GenericModal;

import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 92%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;

export const TH = styled.th`
  width: 20px;
  background-color: ${({ dark }) => (dark ? '#E2E2E2' : 'white')};
  @media screen and (max-width: 768px) {
    height: 80px;
  }
`;

export const TD = styled.td`
  min-width: 35px;
  cursor: pointer;
  text-align: center;
  position: relative;
  background-color: ${({ odd, dark }) => {
    if (odd && dark) {
      return '#C2C2C2';
    } else if (odd && !dark) {
      return '#DFDFDF';
    } else if (!odd && dark) {
      return '#DEDEDE';
    } else {
      return '#FFFFFF';
    }
  }};

  ${({ isDuplicated }) =>
    isDuplicated &&
    css`
      &:after {
        content: '*';
        position: absolute;
        font-size: 30px;
        line-height: 15px;
        left: -18px;
        top: 15px;
      }
    `}
`;

export const OptionsText = styled.p`
  font-size: 13px;
  font-weight: 400;
  margin: 0 auto;
  height: 100%;

  @media screen and (max-width: 768px) {
    text-align: left;
    padding: 7px 0 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
`;

export const FirstOptionsText = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin: 6px 0;

  @media screen and (min-width: 769px) {
    font-size: 16px;
  }
`;

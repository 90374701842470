import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *{
        ::selection {
            background: none
        }
        ::-moz-selection {
            background: none
        }
    }

    html {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        scroll-behavior: smooth;
    }
    
    body {
        margin: 0;

        overflow-y: auto;
        overflow-x: hidden;
    }
    a {
        text-decoration: none;
        display: inline;
        color: black;
    }

    input {
        :focus {
            outline-width: 0;
        }

        :focus::placeholder {
            color: transparent;
        }
    }

    button {
        :focus {
            outline-width: 0;
        }
    }
`;

import styled from 'styled-components';

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: sticky;
  top: ${({ topPosition }) => topPosition && `${topPosition}px`};
  transition: 0.3s ease top;
`;

export const Text = styled.span`
  font-size: 22px;
  grid-column: 2;
`;

import React from 'react';
import { connect } from 'react-redux';
import { TD, FirstOptionsText, Bar, Radius, Fill } from './styles';

const TableRowComponent = ({
  handleClick,
  index,
  id,
  asignatura,
  docente,
  dark,
  answerData,
  reactivo,
  options,
  isDuplicated,
  agrupadores,
  reactivos,
  questionIndex,
}) => {
  const respuestaFiltrada = answerData.respuestas.find(
    (respuesta) =>
      respuesta.id_asignacion === id && reactivo.id === respuesta.id_reactivo,
  );

  let agrupadorActual;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador;
    }
  });

  return (
    <tr>
      <TD
        isDuplicated={isDuplicated}
        odd={index % 2 === 0}
        style={{ borderRight: '2px solid #b1b1b1' }}>
        <FirstOptionsText>{asignatura}</FirstOptionsText>
      </TD>
      <TD style={{ paddingLeft: '11px' }} odd={index % 2 === 0}>
        <FirstOptionsText>{docente}</FirstOptionsText>
      </TD>
      {options.map(({ id, nombre, valor }, i) => (
        <TD
          onClick={() => handleClick(nombre, i, id)}
          cursor='pointer'
          key={id}
          odd={i % 2 === 0}
          dark={dark}>
          <Radius>
            <Fill
              selected={
                respuestaFiltrada && respuestaFiltrada.valor_respuesta >= i + 1
              }
            />
          </Radius>
          {options.length > i + 1 && (
            <Bar
              selected={
                respuestaFiltrada && respuestaFiltrada.valor_respuesta > i + 1
              }
            />
          )}
        </TD>
      ))}
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
    answerData: state.answerData,
    agrupadores: state.agrupadores,
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
  };
};

const mapDispatchToProps = {};

export const TableRow = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableRowComponent);

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setShowFixed } from '../../actions';

import { ButtonExit } from '../Buttons';
import {
  UserBarContainer,
  LittleGrayBar,
  LittleRedBar,
  TextRow,
  FirstSection,
  SecondSection,
  TextContainer,
  DataContiner,
  Text,
  DateText,
  Ciclo,
  NameText,
  ModalidadText,
  MatriculaText,
  Span,
  TipoEvaluacionText,
} from './styles';

const UserInfo = ({ user, evalInfo, showFixed, setShowFixed, rawType }) => {
  const location = useLocation();

  const barContainer = useRef(null);

  useEffect(() => {
    const onScroll = (e) => {
      const newShowFixed =
        window.scrollY > barContainer.current.clientHeight + 10;
      showFixed !== newShowFixed && setShowFixed(newShowFixed);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [showFixed]);

  const matches = useMediaQuery('(max-width: 480px)');

  return (
    <UserBarContainer id='user-info' ref={barContainer}>
      <LittleGrayBar></LittleGrayBar>
      <LittleRedBar></LittleRedBar>
      <TextContainer>
        <FirstSection>
          <TextRow>
            <NameText>
              <Text>Nombre Alumno: </Text>
              <Span>
                {user.nombre || ''} {user.apellido_paterno || ''}{' '}
                {user.apellido_materno || ''}
              </Span>
            </NameText>
            {rawType !== '' ? (
              <TipoEvaluacionText>
                <Text>Evaluacion: </Text>
                <Span>{rawType}</Span>
              </TipoEvaluacionText>
            ) : null}
          </TextRow>
          <DataContiner>
            <ModalidadText>
              <Text>Nombre programa: </Text>
              <Span>{user.nombre_programa}</Span>
            </ModalidadText>
            <MatriculaText>
              <Text>Nivel: </Text>
              <Span>{user.nivel}</Span>
            </MatriculaText>
            <MatriculaText>
              <Text>Matrícula: </Text>
              <Span>{user.clave}</Span>
            </MatriculaText>
            <DateText>
              <Ciclo>
                <Text>Campus: </Text>
                <Span>{user.campus}</Span>
              </Ciclo>
            </DateText>
          </DataContiner>
        </FirstSection>
        {(location.pathname === '/seleccion' && matches) ||
        (location.pathname === '/intervalo' && matches) ||
        (location.pathname === '/multiple' && matches) ||
        (location.pathname === '/abierta' && matches) ||
        (location.pathname === '/opcion-multiple' && matches) ||
        (location.pathname === '/presentacion' && matches) ||
        (location.pathname === '/envio' && matches) ? (
          <ButtonExit userInfo />
        ) : null}
      </TextContainer>
    </UserBarContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    evalInfo: state.evalInfo,
    showFixed: state.showFixed,
    rawType: state.rawType,
  };
};

const mapDispatchToProps = {
  setShowFixed,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

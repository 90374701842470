import styled from 'styled-components';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    Grid,
    Box
  } from "@material-ui/core"

export const BoxFilosofiaTitle = styled(Box)`
    font-weight: bold;
    text-align: center;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
    background-color: #ddd;
`;

export const BoxFilosofia = styled(Box)`
    min-height: 30px;
    height: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
`;

export const ModalPlaneacionContenedor = styled.div`
    overflow: auto;
    height: calc(75vh - 100px);
`;

export const ModalPlaneacionHeader = styled.div`
    position: relative;
    text-align: center;
`;

export const HeaderFecha = styled.div`
    position: absolute;
    right: 20px;
    top: 10px
`;

export const HeaderImg = styled.img`
    width: 200px;
    margin-top: 20px;
`;

export const HeaderTitle = styled.h4`
    font-size: 1.75em;
    margin: 5px;
`;

export const ModalPlaneacionBody = styled.div`
    padding: 5px;
`;

export const BodySubTitle = styled.h5`
    text-transform: uppercase;
    color: #888;
    margin: 10px 10px;
    text-aling:left !important;
`;
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Progress from '../components/Progress';
import { ButtonExit } from '../components/Buttons';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 3rem 115px;
  overflow-y: auto;
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin: 2rem 2rem 115px;
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 1rem 115px;
  }
`;

const Title = styled.p`
  font-size: 35px;
  text-align: center;
  margin: 0;
  @media screen and (min-width: 376px) {
    font-size: 30px;
  }
  @media screen and (min-width: 550px) {
    font-size: 35px;
  }
  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
  @media screen and (min-width: 1025px) {
    font-size: 50px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 55px;
  }
  @media screen and (min-width: 1921px) {
    font-size: 60px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin: 4rem 0 0;
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 0 0;
  }
`;

const Button = styled.button`
  width: 340px;
  height: 42px;
  background-color: #191919;
  border-radius: 40px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
`;

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  margin-bottom: 25px;
  padding-bottom: 10px;
`;

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
`;

const Presentacion = ({
  agrupadores,
  reactivos,
  questionIndex,
  history,
  evalInfo,
  isList,
}) => {
  let name = '';

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      name = agrupador.nombre;
    }
  });

  const handleClick = () => {
    if (isList) {
      history.push('/multiple');
    } else {
      if (reactivos[questionIndex].tipo === 'seleccion simple') {
        history.push('/seleccion');
      } else if (reactivos[questionIndex].tipo === 'seleccion multiple') {
        history.push('/opcion-multiple');
      } else {
        history.push(`/${reactivos[questionIndex].tipo}`);
      }
    }
  };

  const matches = useMediaQuery('(min-width: 481px)');

  return (
    <>
      <Container>
        <Title>{name}</Title>
        <ButtonContainer>
          <Button onClick={handleClick}>Siguiente</Button>
        </ButtonContainer>
      </Container>

      <ControlsWrapper>
        <Controls>
          {matches && <ButtonExit />}
          <Progress disabled canContinue step={questionIndex + 1} />
        </Controls>
      </ControlsWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  agrupadores: state.agrupadores,
  reactivos: state.reactivos,
  questionIndex: state.questionIndex,
  evalInfo: state.evalInfo,
  isList: state.isList,
});

export default connect(mapStateToProps)(Presentacion);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TD, FirstOptionsText, Input } from './styles';

const TableRow = ({
  handleChange,
  index,
  id,
  asignatura,
  docente,
  answerData,
  reactivo,
  isDuplicated,
}) => {
  const respuestaFiltrada = answerData.respuestas.find(
    (respuesta) =>
      respuesta.id_asignacion === id && reactivo.id === respuesta.id_reactivo,
  );

  const [inputValue, setInputValue] = useState(
    respuestaFiltrada ? respuestaFiltrada.respuesta : '',
  );

  const onChange = (ev) => {
    setInputValue(ev.target.value);
  };

  const onBlur = (ev) => {
    handleChange(ev.target.value);
  };

  return (
    <tr>
      <TD
        isDuplicated={isDuplicated}
        odd={index % 2 === 0}
        style={{ borderRight: '2px solid #b1b1b1' }}>
        <FirstOptionsText>{asignatura}</FirstOptionsText>
      </TD>
      <TD style={{ paddingLeft: '11px' }} odd={index % 2 === 0}>
        <FirstOptionsText>{docente}</FirstOptionsText>
      </TD>
      <TD style={{ minWidth: '230px' }} odd={index % 2 === 0} dark>
        <Input
          type='text'
          name='respuesta'
          placeholder='Escribe tu respuesta...'
          // onChange={(ev) => handleChange(ev.target.value)}
          // value={respuestaFiltrada ? respuestaFiltrada.respuesta : ''}
          onChange={onChange}
          value={inputValue}
          onBlur={onBlur}
        />
      </TD>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
    answerData: state.answerData,
    agrupadores: state.agrupadores,
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
  };
};
export default connect(mapStateToProps)(TableRow);

import React, { useState } from 'react';
import { Option, SquareCheck, TextLabel } from './styles';

const SquareCheckbox = ({ id, label, handleClick, size, checked }) => {
  const [isChecked, setChecked] = useState(false);
  const handleChange = (ev) => {
    handleClick && handleClick(ev);
    setChecked(!isChecked);
  };
  return (
    <Option>
      {label && <TextLabel>{label}</TextLabel>}
      <input
        style={{ display: 'none' }}
        type='checkbox'
        onChange={handleChange}
        id={id}
      />
      <SquareCheck size={size} checked={checked} htmlFor={id}></SquareCheck>
    </Option>
  );
};

export default SquareCheckbox;

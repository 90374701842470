import styled from 'styled-components';

export const UserBarContainer = styled.div`
  width: 100vw;
  margin-top: 95px;
  display: none;
  flex-shrink: 0;
  position: fixed;
  z-index: 199;
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  top: ${({ show }) => (show ? '0' : '-143px')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: 0.4s ease top, 0.4s ease opacity;

  @media screen and (max-width: 615px) {
    display: flex;
    top: ${({ show }) => (show ? '0' : '-123px')};
  }

  @media screen and (max-width: 425px) {
    top: ${({ show }) => (show ? '0' : '-143px')};
    margin-top: 65px;
  }

  @media screen and (max-width: 330px) {
    top: ${({ show }) => (show ? '1px' : '-156px')};
    margin-top: 60px;
  }

  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    display: flex;
    margin-top: 60px;
    top: ${({ show }) => (show ? '0' : '-123px')};
  }
`;

export const LittleGrayBar = styled.div`
  background-color: #e2e2e2;
  width: 5%;
`;

export const LittleRedBar = styled.div`
  background-color: red;
  width: 1%;

  @media screen and (max-width: 600px) {
    width: 2%;
  }
`;

export const MainBar = styled.div`
  background-color: #e2e2e2;
  width: 94%;
  display: flex;
  justify-content: space-between;
  padding: 7px 0 7px 50px;

  @media screen and (max-width: 1024px) {
    padding: 7px 0 7px 20px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 7px 15px;
  }

  @media screen and (max-width: 600px) {
    width: 94%;
  }
`;

export const TextRow = styled.div`
  @media screen and (min-width: 769px) {
    margin: 7px 0;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    margin: 4px 0;
  }
`;
export const FirstSection = styled.div`
  width: 100%;
  @media screen and (min-width: 769px) {
    width: 100%;
  }
`;
export const SecondSection = styled.div`
  @media screen and (min-width: 769px) {
    width: 35%;
  }
`;

export const DataContiner = styled.div`
  @media screen and (min-width: 769px) {
    display: flex;
    margin: 7px 0;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    margin: 4px 0;
  }
`;

export const Text = styled.span`
  color: red;
`;

export const Ciclo = styled.span`
  margin-right: 22px;
`;

export const TextContainer = styled.p`
  font-size: 18px;
  width: calc(100% - 100px);
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    font-size: 11px;
  }
`;

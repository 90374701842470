import React from 'react';
import { connect } from 'react-redux';

import { Container, Floater } from './styles';

const Footer = ({ version }) => (
  <Container>
    <Floater float='left'>{version}</Floater>
    <Floater float='right'>Powered by Jalisoft S de RL de CV</Floater>
  </Container>
);

const mapStateToProps = (state) => ({
  version: state.version,
});

export default connect(mapStateToProps)(Footer);

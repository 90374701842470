import React from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

import { setAnswerData } from '../../actions';

const SeleccionItem = ({
  agrupadores,
  asignaciones,
  answerData,
  setAnswerData,
  reactivo,
}) => {
  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivo),
  )[0];

  const handleChange = (res, val, id_res) => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id,
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivo.id,
      );

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'seleccion',
          },
        ],
      });
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'seleccion',
          },
        ],
      });
    }
  };

  const respuestaFiltrada = answerData.respuestas.find(
    (respuesta) => reactivo.id === respuesta.id_reactivo,
  );

  return (
    <>
      <FormControl component='fieldset'>
        <RadioGroup>
          {reactivo.opcions.map(({ nombre, id }, i) => {
            const isChecked =
              respuestaFiltrada && respuestaFiltrada.valor_respuesta === i;

            return (
              <FormControlLabel
                key={i}
                value={i}
                control={<Radio />}
                label={nombre}
                checked={isChecked}
                onChange={() => handleChange(nombre, i, id)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    asignaciones: state.asignaciones,
    answerData: state.answerData,
    showFixed: state.showFixed,
  };
};

const mapDispatchToProps = {
  setAnswerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeleccionItem);

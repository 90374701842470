import React from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SquareCheckbox from '../SquareCheckbox';

import { TD, FirstOptionsText } from './styles';

const TableRow = ({
  id,
  asignatura,
  docente,
  options,
  index,
  handleClick,
  reactivos,
  reactivo,
  questionIndex,
  answerData,
  isDuplicated,
}) => {
  const matches = useMediaQuery('(max-width:768px)');

  const respuestaActual = answerData.respuestas.find(
    (respuesta) =>
      respuesta.id_asignacion === id && reactivo.id === respuesta.id_reactivo,
  );

  let idsRespuesta = [];
  let idsAsignacion = [];

  if (respuestaActual) {
    respuestaActual.respuestas.forEach((res) => {
      idsRespuesta = [...idsRespuesta, res.id_respuesta];
    });
    respuestaActual.respuestas.forEach((res) => {
      idsAsignacion = [...idsAsignacion, res.id_asignacion];
    });
  }

  return (
    <tr>
      <TD
        isDuplicated={isDuplicated}
        odd={index % 2 === 0}
        style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
        <FirstOptionsText>{asignatura}</FirstOptionsText>
      </TD>
      <TD style={{ paddingLeft: '11px' }} odd={index % 2 === 0}>
        <FirstOptionsText>{docente}</FirstOptionsText>
      </TD>
      {options.map((option, i) => {
        const onClick = () => {
          handleClick(option.nombre, i, option.id);
        };
        return (
          <TD
            key={i}
            odd={i % 2 === 0}
            dark={index % 2 === 0}
            onClick={onClick}
            id={`${id}-1`}>
            <SquareCheckbox
              size='14px'
              checked={idsRespuesta.includes(option.id)}
            />
          </TD>
        );
      })}
    </tr>
  );
};

const mapStateToProps = (state) => ({
  reactivos: state.reactivos,
  questionIndex: state.questionIndex,
  answerData: state.answerData,
});

export default connect(mapStateToProps)(TableRow);

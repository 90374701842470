import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import GenericTable from "../components/GenericTable";
import {
  setDetalleSyllabus,
  setLoading,
  setModal,
  setRawType,
} from "../actions";
import Modal from "../components/GenericModal";
import { getRequest, postRequest } from "../utils/requester";
import GenericModal from "../components/GenericModal";
import ModalPlaneacion from "../components/ModalPlaneacion";
import { toast } from "react-toastify";
const TableContainer = styled.div`
  margin: 0 50px 20px;
  @media screen and (max-width: 480px) {
    margin: 0 20px 50px;
  }
`;

const H4 = styled.h4`
  font-size: 23px;
  margin-top: 0;
  font-weight: 300;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-weight: 300;
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  text-align: center;
  padding: 10px;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Button = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? "#6e6e6e" : "#191919")};
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 20px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  font-size: 15px;
  border: none;

  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`;

const ButtonSave = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? "#6e6e6e" : "#FF2A01")};
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 25px;
  cursor: pointer;
  font-size: 15px;
  border: none;
  margin: 5px;
  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`;

const ButtonSalir = styled.button`
  background-color: #878787;
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 25px;
  cursor: pointer;
  font-size: 15px;
  border: none;

  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`;

const ButtonDownload = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? "#6e6e6e" : "white")};
  color: black;
  border-radius: 10px;
  width: 100px;
  height: 25px;
  cursor: pointer;
  font-size: 15px;
  border: none;

  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`;

const Textarea = styled.textarea`
  height: 120px;
  border-radius: 15px;
  padding: 15px 20px;
  margin: 15px 0;
`;

const CharacterCounter = styled.span`
  text-align: right;
`;

const SyllabusDetalle = ({
  detalleSyllabus,
  setLoading,
  setRawType,
  setDetalleSyllabus,
}) => {
  const [modalPlaneacion, setModalPlaneacion] = useState(false);
  const [loadingPlaneacion, setLoadingPlaneacion] = useState(false);
  const [planeacionAbierta, setPlaneacionAbierta] = useState({});
  const [modalComentario, setModalComentario] = useState(false);
  const [infoModalAbierto, setInfoModalAbierto] = useState({});
  const [modalSalir, setModalSalir] = useState(false);
  const [updaterState, updateState] = useState(0);
  const [comentario, setComentario] = useState("");
  const [comentarioLength, setComentarioLength] = useState(0);
  const history = useHistory();
  const refComentario = useRef(null);

  const headers = [
    {
      name: "Nombre Materia",
      width: "",
      center: true,
    },
    {
      name: "Nombre del Docente",
      width: "",
      center: true,
    },
    {
      name: "Planeación",
      width: "",
      center: true,
    },
    {
      name: "Recibí planeación y estoy de acuerdo",
      width: "140px",
      center: false,
    },
    {
      name: "Recibí planeación y tengo comentarios",
      width: "140px",
      center: false,
    },
    {
      name: "Comentarios",
      width: "120px",
      center: true,
    },
    {
      name: "Estatus",
      width: "120px",
      center: true,
    },
  ];

  useEffect(() => {
    setRawType("");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const saveButtonClick = async () => {
    setLoading(true);
    try {
      let weBody = detalleSyllabus
        .filter((item) => {
          if (
            item.meta_data["Recibí planeación y estoy deacuerdo"] === "Y" ||
            item.meta_data["Recibí planeación y tengo comentarios"] === "Y"
          ) {
            return true;
          }

          return false;
        })
        .map((item) => ({
          id: item.id_sqs,
          meta_data: item.meta_data,
        }));
      const resp = await postRequest("/syllabus/alumno/guardar", weBody);
      if (resp.s === "OK") {
        toast.success(resp.m);
      }

      history.push("/");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmitComentario = () => {
    const comentarioNuevo = refComentario.current.value;
    let nuevaInfoModalAbierto = {
      ...infoModalAbierto,
      meta_data: {
        ...infoModalAbierto["meta_data"],
        comentario: comentarioNuevo,
      },
    };
    console.log(nuevaInfoModalAbierto);
    let registrosProgramaciones = [...detalleSyllabus];
    let modificado = registrosProgramaciones.map((reg) =>
      reg["nombre_materia"] === nuevaInfoModalAbierto["nombre_materia"]
        ? nuevaInfoModalAbierto
        : reg
    );
    setDetalleSyllabus([...modificado]);
    setModalComentario(false);
  };

  const openDetallePlaneacion = async (planeacion) => {
    if (Object.keys(planeacion).length === 0) {
      toast.error("No se pudo cargar la informacion");
      return;
    }
    if (Object.keys(planeacion.planeacion).length === 0) {
      toast.error("No se pudo cargar la informacion");
      return;
    }

    if (!planeacion.generales) {
      const generales = await fetchGenerales();
      planeacion.generales = generales;
    }else{
      setLoadingPlaneacion(true);
      setModalPlaneacion(true);
      planeacion.planeacion.sesiones.forEach((sesion) => {
        if (!sesion.bibliografias) {
          if (sesion.temas[0]) {
            if (sesion.temas[0].bibliografias) {
              sesion.bibliografias = sesion.temas[0].bibliografias;
            } else {
              sesion.bibliografias = [];
            }
          } else {
            sesion.bibliografias = [];
          }
        }
      });
      setPlaneacionAbierta(planeacion);
      setLoadingPlaneacion(false);
    };
  }

  const dataToTable = useMemo(() => {
    try {
      return detalleSyllabus.map((reg, i) => [
        reg["nombre_materia"],
        reg["nombre_docente"],
        <ButtonDownload
          onClick={() => {
            if (reg["planeacion"].url.length > 0){
              let a = document.createElement("a");
              a.href = reg["planeacion"].url;
              a.setAttribute("download", "documento");
              a.click();
            }
            else{
              openDetallePlaneacion(reg["planeacion"]);
            }
          }}
        >
          Descargar
        </ButtonDownload>,
        <RadioContainer
          onClick={(e) => {
            changeRadioButton(
              reg,
              "Recibí planeación y estoy deacuerdo",
              1,
              i,
              detalleSyllabus
            );
          }}
        >
          <input
            checked={
              reg.meta_data["Recibí planeación y estoy deacuerdo"] === "Y"
            }
            type='radio'
          />
        </RadioContainer>,
        <RadioContainer
          onClick={(e) => {
            changeRadioButton(
              reg,
              "Recibí planeación y tengo comentarios",
              1,
              i,
              detalleSyllabus
            );
          }}
        >
          <input
            checked={
              reg.meta_data["Recibí planeación y tengo comentarios"] === "Y"
            }
            type='radio'
          />
        </RadioContainer>,
        <Button
          disabled={
            reg.meta_data["Recibí planeación y tengo comentarios"] !== "Y"
          }
          isDisabled={
            reg.meta_data["Recibí planeación y tengo comentarios"] !== "Y"
          }
          onClick={() => handleOpenModalComentarios(reg, i)}
        >
          Agregar
        </Button>,
        reg["estatus"],
      ]);
    } catch {
      return [];
    }
  }, [detalleSyllabus, updaterState]);

  async function fetchGenerales(url) {
    let data = {
      vertical: "Ciencias sociales",
      licenciatura: "Comunicación y medios digitales",
      academia: "Comunicación",
      asignatura: "Guionismo para medios",
      clave_materia: "Comu0303a",
      asignatura_antecedente: "No aplica",
      modalidad: "Semestral",
      ciclo: "Segundo",
      nombre_profesor: "(debe incluir su grado académico)",
      correo_institucional: "",
    };
    let json = [
      {
        name: "Vertical",
        content: data.vertical,
      },
      {
        name: "Licenciatura",
        content: data.licenciatura,
      },
      {
        name: "Academia",
        content: data.academia,
      },
      {
        name: "Asignatura",
        content: data.asignatura,
      },
      {
        name: "Clave de la materia sep/banner",
        content: data.clave_materia,
      },
      {
        name: "Asignatura Antecedente",
        content: data.asignatura_antecedente,
      },
      {
        name: "Modalidad",
        content: data.modalidad,
      },
      {
        name: "Ciclo",
        content: data.ciclo,
      },
      {
        name: "Nombre del profesor",
        content: data.nombre_profesor,
      },
      {
        name: "Correo Electrónico Institucional",
        content: data.correo_institucional,
      },
    ];
    return json;
  }

  const changeRadioButton = (reg, campo, _value, index, dataCompleta) => {
    if (campo === "Recibí planeación y estoy deacuerdo") {
      reg.meta_data[campo] = "Y";
      reg.meta_data["Recibí planeación y tengo comentarios"] = "N";
    } else {
      reg.meta_data[campo] = "Y";
      reg.meta_data["Recibí planeación y estoy deacuerdo"] = "N";
    }
    dataCompleta[index] = reg;
    setDetalleSyllabus(dataCompleta);
    updateState(Math.random());
  };

  const handleOpenModalComentarios = (reg) => {
    setInfoModalAbierto(reg.meta_data);
    setComentario(reg.meta_data.comentario);
    setComentarioLength(reg.meta_data.comentario.length);
    setInfoModalAbierto(reg);
    setModalComentario(true);
  };

  return (
    <>
      <TableContainer>
        <H4>A continuación se muestran las programaciones por aceptar</H4>
        <GenericTable
          headers={headers}
          data={dataToTable || []}
          voidTable='No hay programaciones por revisar'
          actionsRows={[3, 4]}
          headerBlanco={true}
        />
      </TableContainer>
      <ButtonsContainer>
        <ButtonSalir
          onClick={() => {
            setModalSalir(true);
          }}
        >
          Salir
        </ButtonSalir>
        <ButtonSave onClick={saveButtonClick}>Guardar</ButtonSave>
      </ButtonsContainer>

      <GenericModal
        title='Agregar Comentario'
        handleClose={() => setModalComentario(false)}
        handleSubmit={handleSubmitComentario}
        loading={false}
        open={modalComentario}
      >
        <Textarea
          placeholder='Agrega un comentario...'
          ref={refComentario}
          onChange={(ev) => {
            if (ev.target.value.length <= 1500) {
              setComentarioLength(ev.target.value.length);
              setComentario(ev.target.value);
            }
          }}
          value={comentario}
        />
        <CharacterCounter>{comentarioLength}/1500</CharacterCounter>
      </GenericModal>
      <GenericModal
        title='Salir'
        handleClose={() => setModalSalir(false)}
        handleSubmit={() => {
          history.push("/");
        }}
        loading={false}
        open={modalSalir}
      >
        <ButtonsContainer>
          <h3>¿Desea cerrar planeación?</h3>
        </ButtonsContainer>
      </GenericModal>
      <ModalPlaneacion
        title='Descargar Planeación'
        handleClose={() => setModalPlaneacion(false)}
        handleSubmit={() => setModalPlaneacion(false)}
        loading={loadingPlaneacion}
        open={modalPlaneacion}
        planeacion={planeacionAbierta.planeacion || { sesiones: [] }}
        generales={planeacionAbierta.generales || []}
        criterios={planeacionAbierta.criterios || []}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    detalleSyllabus: state.detalleSyllabus,
  };
};

const mapDispatchToProps = {
  setDetalleSyllabus,
  setLoading,
  setRawType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SyllabusDetalle);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setAnswerData } from '../../actions';

import {
  PreguntasContainer,
  OptionsContainer,
  Option,
  TextLabel,
  SquareCheck,
} from './styles';

const PreguntaOpcionMultiple = ({
  agrupadores,
  answerData,
  setAnswerData,
  asignaciones,
  reactivo,
}) => {
  const [canContinue, setCanContinue] = useState(false);

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivo),
  )[0];

  const respuestaActual = answerData.respuestas.find(
    (respuesta) => respuesta.id_reactivo === reactivo.id,
  );

  const handleChange = (res, id_res) => {
    if (respuestaActual) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivo.id,
      );

      const isIncluded = respuestaActual.respuestas.find(
        (res) => res.id_respuesta === id_res,
      );

      if (isIncluded) {
        const newRes = respuestaActual.respuestas.filter(
          (res) => res.id_respuesta !== id_res,
        );
        setAnswerData({
          ...answerData,
          respuestas: [
            ...respuestasFiltradas,
            {
              ...respuestaActual,
              respuestas: [...newRes],
            },
          ],
        });
      } else {
        setAnswerData({
          ...answerData,
          respuestas: [
            ...respuestasFiltradas,
            {
              ...respuestaActual,
              respuestas: [
                ...respuestaActual.respuestas,
                {
                  id_respuesta: id_res,
                  respuesta: res,
                },
              ],
            },
          ],
        });
      }
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuestas: [
              {
                id_respuesta: id_res,
                respuesta: res,
              },
            ],
            tipo: 'seleccion multiple',
          },
        ],
      });
    }
  };

  let ids = [];

  if (respuestaActual) {
    respuestaActual.respuestas.forEach((res) => {
      ids = [...ids, res.id_respuesta];
    });
  }

  if (respuestaActual) {
    const newCanContinue = respuestaActual.respuestas.length > 0;
    canContinue !== newCanContinue && setCanContinue(newCanContinue);
  }

  return (
    <PreguntasContainer>
      <OptionsContainer>
        {reactivo.opcions.map(({ id, nombre }) => (
          <Option key={id}>
            {nombre && <TextLabel>{nombre}</TextLabel>}
            <input
              style={{ display: 'none' }}
              type='checkbox'
              onChange={() => handleChange(nombre, id)}
              id={`checkbox-${id}`}
            />
            <SquareCheck
              checked={ids.includes(id)}
              htmlFor={`checkbox-${id}`}></SquareCheck>
          </Option>
        ))}
      </OptionsContainer>
    </PreguntasContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    answerData: state.answerData,
    asignaciones: state.asignaciones,
  };
};

const mapDispatchToProps = {
  setAnswerData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreguntaOpcionMultiple);

import styled from 'styled-components';

export const FormPage = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to right, #98192c, red);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  max-width: 380px;
  width: 80vw;
  margin-bottom: 3em;

  @media screen and (max-width: 768px) {
    margin-bottom: 3em;
  }
`;

export const Form = styled.form`
  max-width: 380px;
  width: 80vw;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @media screen and (max-width: 425px) {
    margin-top: 55px;
  }
`;

export const Button = styled.button`
  height: 50px;
  background-color: #0000;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  @media screen and (max-width: 768px) {
    height: 45px;
    background-color: #000;
  }

  @media screen and (max-width: 425px) {
    height: 40px;
    font-size: 21px;
    width: 240px;
  }
`;

export const LoaderContainer = styled.div`
  padding-top: 3px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Text = styled.span`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Title = styled.h1`
  text-align: center;
  color: white;
  margin-top: -35px;
  font-size: 40px;
  margin-bottom: 0px;
`;

export const ExternalLink = styled.a`
  position: absolute;
  bottom: 30px;
  left: 5px;
  color: #fff;
  text-decoration: underline;
  font-size: 20px;
`;

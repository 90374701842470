export const setUser = (payload) => ({
  type: 'SET_USER',
  payload,
});

export const setModal = (payload) => ({
  type: 'SET_MODAL',
  payload,
});

export const setRawType = (payload) => ({
  type: 'SET_RAW_TYPE',
  payload,
});

export const setType = (payload) => ({
  type: 'SET_TYPE',
  payload,
});

export const setAuthModal = (payload) => ({
  type: 'SET_AUTH_MODAL',
  payload,
});

export const setVersion = (payload) => ({
  type: 'SET_VERSION',
  payload,
});

export const setLoading = (payload) => ({
  type: 'SET_LOADING',
  payload,
});

export const setAuth = (payload) => ({
  type: 'SET_AUTH',
  payload,
});

export const setSelectedOptions = (payload) => ({
  type: 'SET_SELECTIONS',
  payload,
});

export const setSelectedOptionsDesktop = (payload) => ({
  type: 'SET_SELECTIONS_DESKTOP',
  payload,
});

export const setSelectedOptionsAbierta = (payload) => ({
  type: 'SET_ABIERTA',
  payload,
});

export const setSelectedOptionsAbiertaDesktop = (payload) => ({
  type: 'SET_ABIERTA_DESKTOP',
  payload,
});

export const setSelectedOptionsMultipleDesktop = (payload) => ({
  type: 'SET_MULTIPLE_DESKTOP',
  payload,
});

export const setMultipleOptions = (payload) => ({
  type: 'SET_MULTIPLE_OPTIONS',
  payload,
});

export const clearAnswerData = () => ({
  type: 'CLEAR_ANSWER_DATA',
});

export const clearEvalInfo = () => ({
  type: 'CLEAR_EVAL_INFO',
});

export const setEvals = (payload) => ({
  type: 'SET_EVALS',
  payload,
});

export const setSyllabus = (payload) => ({
  type: 'SET_SYLLABUS',
  payload,
});

export const setDetalleSyllabus = (payload) => ({
  type: 'SET_DETALLE_SYLLABUS',
  payload,
});

export const setErrorEvaluacion = (payload) => ({
  type: 'SET_ERROR_EVALUACION',
  payload,
});

export const setAsignaciones = (payload) => ({
  type: 'SET_ASIGNACIONES',
  payload,
});

export const setAgrupadores = (payload) => ({
  type: 'SET_AGRUPADORES',
  payload,
});

export const setEvalInfo = (payload) => ({
  type: 'SET_EVAL_INFO',
  payload,
});

export const setQuestionIndex = (payload) => ({
  type: 'SET_QUESTION_INDEX',
  payload,
});

export const setReactivos = (payload) => ({
  type: 'SET_REACTIVOS',
  payload,
});

export const setAnswerData = (payload) => ({
  type: 'SET_ANSWER_DATA',
  payload,
});

export const setLoadingRespuesta = (payload) => ({
  type: 'SET_LOADING_RESPUESTA',
  payload,
});

export const setIsList = (payload) => ({
  type: 'SET_IS_LIST',
  payload,
});

export const setRenderedTables = (payload) => ({
  type: 'SET_RENDERED_TABLES',
  payload,
});

export const setShowFixed = (payload) => ({
  type: 'SET_SHOW_FIXED',
  payload,
});

export const setTokenKey = (payload) => ({
  type: 'SET_TOKEN_KEY',
  payload,
});

export const setIndexHistory = (payload) => ({
  type: 'SET_INDEX_HISTORY',
  payload,
});

export const setDuration = (payload) => ({
  type: 'SET_DURATION',
  payload,
});

import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, hideHeader }) => (
  <>
    {!hideHeader && <Header />}
    {children}
    <Footer />
  </>
);

export default Layout;

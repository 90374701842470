import React, { useState } from 'react';
import { connect } from 'react-redux';

import { setAnswerData } from '../../actions';

import { Wrapper, InputContainer, Text, Input, CounterText } from './styles';

const AbiertaItem = ({
  agrupadores,
  reactivo,
  answerData,
  setAnswerData,
  asignaciones,
}) => {
  const respuestaActual = answerData.respuestas.find(
    (respuesta) => respuesta.id_reactivo === reactivo.id,
  );

  const [input, setInput] = useState(
    respuestaActual ? respuestaActual.respuesta : '',
  );

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivo),
  )[0];

  const handleChange = (evt) => {
    setInput(evt.target.value);
  };

  const handleBlur = () => {
    handleAnswerData();
  };

  const handleAnswerData = () => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id,
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivo.id,
      );

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: reactivo.opcions[0].id,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: input,
            tipo: 'abierta',
          },
        ],
      });
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: reactivo.opcions[0].id,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivo.id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: input,
            tipo: 'abierta',
          },
        ],
      });
    }
  };

  return (
    <Wrapper>
      <InputContainer>
        <Text>Respuesta:</Text>
        <Input
          type='text'
          placeholder='Escriba una respuesta'
          value={input}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength='1000'
        />
        <CounterText>
          {respuestaActual ? respuestaActual.respuesta.length : 0}/1000
        </CounterText>
      </InputContainer>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    answerData: state.answerData,
    asignaciones: state.asignaciones,
  };
};

const mapDispatchToProps = {
  setAnswerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AbiertaItem);

import React from 'react';
import { BounceLoaderContainer } from './styles';
import './spinkit.css';

export const PulseLoader = () => {
  return (
    <BounceLoaderContainer>
      <div className='sk-bounce'>
        <div className='sk-bounce-dot'></div>
        <div className='sk-bounce-dot'></div>
      </div>
    </BounceLoaderContainer>
  );
};
